<mat-card class="commonBorder">
  <mat-drawer-container class="sidenav-container" style="height: 75vh; ">
    <mat-drawer mode="side" opened>
      <div class="">
        <div class="col-md-12">
          <div>
            <mat-label style="font-size: large; font-weight: bold;">
              Settings
            </mat-label>
          </div>
          <br><br>
          <mat-label class="material-light">GENERAL</mat-label>
          <mat-nav-list>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(1)">
              <a matLine >Store Name</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(2)">
              <a matLine >Contact Info</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(3)">
              <a matLine >Store Branding</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(26)">
              <a matLine >Metadata</a>
            </mat-list-item>
            <!-- <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(4)">
              <a matLine >Store Notifications</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(5)">
              <a matLine >Store Policies</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']"  (click)="showForm(6)">
              <a matLine >Privacy Settings</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']"  (click)="showForm(7)">
              <a matLine >Social Media</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(8)">
              <a matLine >Product Display Settings</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(9)">
              <a matLine >Store Closed Page</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(10)">
              <a matLine >Designer</a>
            </mat-list-item> -->
            
          </mat-nav-list><br><br>
          <mat-label class="material-light">TEMPLATES</mat-label>
          <mat-nav-list>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(22)">
              <a matLine >Order Receipt</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(23)">
              <a matLine >Store Live</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(24)">
              <a matLine >Store Verification</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(25)">
              <a matLine >Disclaimer</a>
            </mat-list-item>
            </mat-nav-list><br><br>
          <!-- <div class="row">
            <div class="col-md-8">
              <mat-label class="material-light">ECOMMERCE</mat-label>
            </div>
            <div class="col-md-4">
              <mat-slide-toggle [checked]="true"></mat-slide-toggle>
            </div>
          </div> -->
          <mat-label class="material-light">E-COMMERCE</mat-label>
          <mat-nav-list>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(11)">
              <a matLine >Products Shipped Date</a>
            </mat-list-item>
            <!-- <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(12)">
              <a matLine >Shipping Methods</a>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(13)">
              <a matLine >Pick Up Methods</a>
            </mat-list-item> -->
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(21)">
              <a matLine >Shipping and Pick Up Methods</a>
            </mat-list-item>
            <!-- <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(14)">
              <a matLine >Checkout Settings</a>
            </mat-list-item> -->
            <!-- <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(15)">
              <a matLine >Custom Order Fields</a>
            </mat-list-item> -->
            <!-- <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(16)">
              <a matLine >Store Commission</a>
            </mat-list-item> -->
          </mat-nav-list><br><br>
          <!-- <div class="row">
            <div class="col-md-8">
              <mat-label class="material-light">ADVANCED</mat-label>
            </div>
          </div>
          <mat-nav-list>
            <mat-list-item [routerLinkActive]="['is-active']" (click)="showForm(17)">
              <a matLine >SEO & Settings</a>
            </mat-list-item>
          </mat-nav-list>
          <div class="padding10"></div> -->
          <!-- <div class="row">
            <mat-nav-list>
              <mat-list-item style="cursor: text;">
                <div class="col-md-9">
                  <a matLine>Ready To Publish</a>
                </div>
                <div class="col-md-3" style="text-align: right;">
                  <mat-slide-toggle [checked]="true"></mat-slide-toggle>
                </div>
              </mat-list-item>
            </mat-nav-list>
          </div> -->
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <mat-card class="commonBorder">
        <div class="row">
          <div class="col-md-12">
            <router-outlet></router-outlet>
          </div>
        </div>
      </mat-card>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-card>
