<!-- nav -->
<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
  <div class="navbar-nav">
      <a class="nav-item nav-link" routerLink="/">Home</a>
      <a class="nav-item nav-link" (click)="logout()">Logout</a>
  </div>
</nav> -->

<!-- main app container -->
<!-- <div class="jumbotron">
  <div class="container">
      <div class="row">
          <div class="col-sm-6 offset-sm-3">
            <button routerLink="/user">User</button>
            <button routerLink="/user/login">Home</button>
              <router-outlet></router-outlet>
          </div>
      </div>
  </div>
</div> -->

<router-outlet></router-outlet>

<div class="fullscreen-spinner" *ngIf="loading">
  <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>