<br><br>
<div class="row  justify-content-center">
  <mat-card class="col-md-4" style="text-align: center;">

    <img width="60%" height="25%" src="../../../../assets/images/Logo.png">
    <br><br>
    <h2>Log In</h2>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-form-field style="width: 100%;">
        <mat-label>Username</mat-label>
        <input type="email" matInput formControlName="email" onkeypress="return event.charCode != 32">
        <mat-error *ngIf="loginForm.get('email').errors?.required">
          Email is required
        </mat-error>
        <mat-error *ngIf="loginForm.get('email').errors?.pattern">
          Please enter a valid email address
          </mat-error>
        <!-- <mat-error *ngIf="loginForm.get('email').errors.required">
          Email is required
        </mat-error> -->
        <!-- <mat-error *ngIf="loginForm.get('email').errors.pattern">
          Please enter a valid email address
        </mat-error> -->
      </mat-form-field>

      <mat-form-field style="width: 100%;">
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password">
        <mat-error *ngIf="loginForm.get('password').errors?.required">
          Password is required
        </mat-error>
      </mat-form-field>
      <span [hidden]="!showResponseMessage" style="color:red">{{errorText}}</span>
      <br>
      <div class="form-group">        
        <button [disabled]="loading" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Login
      </button>
      </div>
    </form>   

  </mat-card>
</div>
