<div class="timer"
  [ngStyle]="{'background-color': layoutData.timer.backgroundColor,'color': layoutData.timer.fontColor}">
  <div class="title">
    ORDER YOUR GEAR NOW
  </div>
  <div class="label">
    STORE CLOSES IN
  </div>
  <div class="count">
    <div><span id="days"> {{daysToDday}} </span>DAYS</div>
    <div><span id="hours"> {{hoursToDday}} </span>HOURS</div>
    <div><span id="minutes"> {{minutesToDday}} </span>MINUTES</div>
    <div><span id="seconds"> {{secondsToDday}} </span>SECONDS</div>
  </div>
</div>