import { Component, HostListener, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { CommonService } from "src/app/core/services/common.service";
import { ProductService } from "src/app/core/services/product.service";
import { SharedService } from "src/app/core/services/shared.service";
import { StoreService } from "src/app/core/services/store.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { Clipboard } from '@angular/cdk/clipboard';
declare var $: any;
const clipboardy = require('clipboardy');

@Component({
  selector: "app-product-view",
  templateUrl: "./product-view.component.html",
  styleUrls: ["./product-view.component.scss"],
})
export class ProductViewComponent implements OnInit {
  isCopied: boolean=false;
  showPersonalizationValue: boolean=false;
  isNamePersonalized: boolean=false;
  isNumberPersonalized: boolean=false;
  unitPrice: number=0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private productService: ProductService,
    private sharedServices: SharedService,
    private clipboard: Clipboard
  ) { }

  @HostListener('window:message', ['$event'])
  onMessage(e) {

    if (e.origin != environment.wpUrl.substring(0, environment.wpUrl.length - 1)) {
      return false;
    }

    if (typeof (e.data) == "string") {
      if (e.data.startsWith("apply action - ") && e.data.replace("apply action - ","") == "add to cart") {
        this.addToCart()
      }
    }
  }
  private wpUrl = environment.wpUrl;
  decoGroup
  decoGroupServices
  decoProductSizeAndFonts
  teamStoreId: any;
  purchaseType: any;
  productId: any;
  mapCode: any;
  cartId: any;
  isEdit: boolean = false;
  designId: any;
  product: any;
  quantityGroup: UntypedFormGroup;
  cartItemGrids: UntypedFormArray;
  personalizeValue: boolean = false;
  noImagePlaceholder = "../../../../assets/images/no-image.jpg";
  serviceArray: any[] = [];
  selectedColor: any = "";
  selectedServiceId = 1;
  loading = true;
  //personalizationTypes = []
  //personalizationServices = []
  inkColorArray
  personalizationPropertiesForm: UntypedFormArray
  decorationPropertiesForm: UntypedFormArray = new UntypedFormArray([])
  decoPrice
  decoPriceLoading = false
  // namePersonalizationQuantity
  // numberPersonalizationQuantity
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if(params.keys.length>0){
        this.initialLoad()
      }

    });
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if(params.keys.length>0){
        if ( !params.keys.includes("isCopiedDesignedProductUrl")){
          this.initialLoad()
        }
        if ( params.keys.includes("isCopiedDesignedProductUrl")) {
          let queryParams =  { ...this.route.snapshot.queryParams };
          if ('isCopiedDesignedProductUrl' in queryParams) {
            delete queryParams.isCopiedDesignedProductUrl;
          }
          const queryParamsString = new URLSearchParams(queryParams).toString();
          const newUrl = `${this.wpUrl+'productview/'}?${queryParamsString}`;
          clipboardy.write(newUrl)
        }
      }

    });
  }

  initialLoad(){
    this.personalizationPropertiesForm = this.formBuilder.array([])
    this.decorationPropertiesForm= new UntypedFormArray([])
    window.parent.postMessage("update title-Product View", "*")
    this.commonService.postScrollMessageToParent()

    this.purchaseType = this.route.snapshot.queryParamMap.get('purchaseType') || parseInt(localStorage.getItem("purchaseType")) || '2';
    this.teamStoreId =  this.route.snapshot.queryParamMap.get('teamStoreId') || parseInt(localStorage.getItem("teamStoreId"));
    this.teamStoreId = this.teamStoreId ? this.teamStoreId : null;
    this.productId =  this.route.snapshot.queryParamMap.get('productId') || this.route.snapshot.paramMap.get("productId") || parseInt(localStorage.getItem("productId"));;
    // if(this.productId==null){
    //   this.productId = localStorage.getItem("productId")
    // }
    this.mapCode = this.route.snapshot.paramMap.get("mapCode");
    //this.getPersonalizationServices()
    //this.getPersonalizationTypes()
    //this.getAvailableInkColors()
    //this.createPersonalizationPropertiesForm()
    if (this.productId && this.productId.split("-")[1])
      this.selectedColor = this.productId.split("-")[1];
    this.createQuantityForm(0);

    if (this.purchaseType == 2) {
      // RegularPurchaseWithDesign - 2
      // this.designId = this.route.snapshot.paramMap.get("productId");
      this.designId =this.route.snapshot.queryParamMap.get('productId') ||  this.route.snapshot.paramMap.get("productId")
      //this.designId = this.productId;
      this.cartId = this.route.snapshot.paramMap.get("cartId");
      if (!this.designId || !this.cartId) {
        let count = 0;
        let thisObj = ({} = this);
        window.parent.postMessage("loaded", "*");
        window.addEventListener(
          "message",
          function (e) {
            if (count == 0) {
              thisObj.designId = new String(e.data.productId).toString();

              if (!thisObj.designId || "undefined") {
                thisObj.route.paramMap.subscribe((params) => {
                  if(params.get("productId"))
                  thisObj.designId = params.get("productId");
                  if(params.get("cartId"))
                  thisObj.cartId = params.get("cartId");
                });
                thisObj.route.queryParamMap.subscribe((params) => {
                  if(params.get("productId"))
                      thisObj.designId = params.get("productId");
                 if(params.get("cartId"))
                      thisObj.cartId = params.get("cartId");
                });


              }
              if (thisObj.cartId != null) {
                thisObj.isEdit = true;
                thisObj.getCartDetails();
              }
              thisObj.getDirectProductDetail(thisObj.designId, 1);
              thisObj.productId = localStorage.getItem("productId");
              thisObj.createQuantityForm(thisObj.designId);
              count++;
            }
          },
          false
        );
      } else {
        // direct purcahse
        if (this.cartId != null) {
          this.isEdit = true;
          this.getCartDetails();
        }
        this.getDirectProductDetail(this.designId, 1);
        this.productId = localStorage.getItem("productId");
        this.createQuantityForm(this.designId);
      }
    } else {
      this.route.paramMap.subscribe((params) => {
        this.productId = params.get("productId");
        this.cartId = params.get("cartId");
      });
      if (this.cartId != null) {
        this.isEdit = true;
        this.getCartDetails();
      }
      if (this.purchaseType == 1) {
        // RegularPurchaseWithoutDesign -1
        this.getDirectProductDetail(this.productId, 2);
      } else {
        // PurchaseWithTeamstore - 3
        if (!this.productId) this.productId = localStorage.getItem("productId");
        if (!this.mapCode) this.mapCode = localStorage.getItem("mapCode");
        this.getTeamstoreProductDesignedDetails();
      }
    }
    //this.getAvailableStoreServices();
    if(this.product?.productInfo?.isPersonalize==true && this.purchaseType==3){
      this.showPersonalizationValue=true
    }else{
      this.showPersonalizationValue=false
    }
    //  setTimeout(() => {
    //   if(!this.personalizationPropertiesForm.controls.length)
    //     this.createPersonalizationPropertiesForm()
    // }, 3000);
  }

  createPersonalizationPropertiesForm() {
    this.personalizationPropertiesForm = this.formBuilder.array([])
    if(this.decoProductSizeAndFonts)
    this.decoProductSizeAndFonts[0].personalizationTypes.forEach(type => {
      this.personalizationPropertiesForm.push(this.createPersonalizationPropertiesGroup(type))
    })
     setTimeout(() => {
        if(this.personalizationPropertiesForm?.controls.length>0 ){
          this.personalizationPropertiesForm.controls?.forEach((type,index) => {

          this.personalizationPropertiesForm.controls[index].patchValue({
           serviceId:this.decoGroupServices[0].decoGroupMethodId,
           serviceName:this.decoGroupServices[0].decoGroupMethodName,
         })
        })

        }
     }, 1000);
  }

  createPersonalizationPropertiesGroup(type) {
    return this.formBuilder.group({
      personalizationTypeId: type.id,
      name: type.name,
      //positionId: ['', Validators.required],
      serviceId: [''],
      serviceName:[''],
      sizeId: ['', Validators.required],
      colorId: ['', Validators.required],
      fontId: ['', Validators.required],
    })
  }

  createExistingPersonalizationPropertiesGroup(type) {
    return this.formBuilder.group({
      personalizationTypeId: type.personalizationTypeId,
      name: type.personalizationTypeId == 1 ? "Name" : "Number",
      // name: this.personalizationTypes.find(obj => obj.id == type.personalizationTypeId).type,
      //positionId: (type.positionId || ''),
      serviceId: (type.serviceId ),
      sizeId: (type.sizeId),
      serviceName:(type?.serviceName),
      colorId: (type.colorId),
      fontId: (type.fontId ),
    })
  }

  setPersonalizationPropertiesForm(arr) {
    this.personalizationPropertiesForm = this.formBuilder.array([])
    if (arr.length == 1)
      arr.push({ personalizationTypeId: arr[0].personalizationTypeId == 1 ? 2 : 1 })
    arr.forEach(type => {
      this.personalizationPropertiesForm.push(this.createExistingPersonalizationPropertiesGroup(type))
    })
  }

  setDecorationPropertiesForm(arr) {
    this.decorationPropertiesForm = this.formBuilder.array([])
    arr?.decorationProperties.forEach((obj,index) => {
      this.decorationPropertiesForm.push(this.createDecorationPropertyGroup2(obj,index,arr))
    })
  }

  createQuantityForm(designId) {
    this.quantityGroup = this.formBuilder.group({
      cartId: 0,
      teamStoreId: this.purchaseType == 3 ? this.teamStoreId : 0,
      productId: this.productId,
      isPersonalize: false,
      quantity: [0, Validators.min(1)],
      totalPrice: 0,
      personalizationPrice: 0,
      personalizedQuantity: 0,
      purchaseType: this.purchaseType,
      // designerCartId: 5726,
      designerCartId: designId,
      cartItemGrids: this.formBuilder.array([this.createItemGrids()]),
      serviceId: 0,
      mapCode: this.mapCode || null,
      price: 0,
      decoPrice: 0,
      namePersonalizationQuantity: 0,
      numberPersonalizationQuantity: 0,
    });
  }

  createItemGrids(): UntypedFormGroup {
    let carItemGrid = this.formBuilder.group({
      colorId: new UntypedFormControl(this.selectedColor),
      name: new UntypedFormControl({ value: "", disabled: true }),
      number: new UntypedFormControl({ value: "", disabled: true }),
      size: new UntypedFormControl("0", [Validators.required, Validators.min(1)]),
      quantity: new UntypedFormControl("", Validators.required),
      personalize: new UntypedFormControl(false),
      totalPrice: 0,
      // personalizationPrice: 0,
      personalizationType: 0,
      personalizationNamePrice: 0,
      personalizationNumberPrice: 0,
      decoPrice: 0,
    });
    return carItemGrid;
  }

  updateItemGrid(gridItem) {
    const disabled = !gridItem.personalize;
    let carItemGrid = this.formBuilder.group({
      colorId: gridItem.colorId,
      name: [{ value: gridItem.name, disabled }],
      number: [{ value: gridItem.number, disabled }],
      size: gridItem.sizeId,
      quantity: gridItem.quantity,
      personalize: gridItem.personalize || false,
      totalPrice: gridItem.totalPrice || 0,
      // personalizationPrice: gridItem.personalizationPrice || 0,
      personalizationType: gridItem.personalizationType || 0,
      personalizationNamePrice: gridItem.personalizationNamePrice || 0,
      personalizationNumberPrice: gridItem.personalizationNumberPrice || 0,
      decoPrice: gridItem.decoPrice || 0,
    });
    return carItemGrid;
  }

  addRow() {
    this.cartItemGrids = this.quantityGroup.controls.cartItemGrids as UntypedFormArray;
    this.cartItemGrids.push(this.createItemGrids());
    this.commonService.postHeightToParent("low")
  }

  deleteRow(index) {
    this.cartItemGrids = this.quantityGroup.controls.cartItemGrids as UntypedFormArray;
    if (this.cartItemGrids.length > 1) this.cartItemGrids.removeAt(index);
    this.getTotalQuantity();
  }

  getTeamstoreProductDesignedDetails() {
    this.storeService
      .getProductDetails(this.teamStoreId, this.productId, this.mapCode)
      .subscribe(
        (response) => {
          this.product = response.data;
          if (this.selectedColor == 0)
            this.selectedColor = this.product.productInfo.variants[0].id;
          this.personalizeValue = this.product.productInfo.isPersonalize;
              this.isNamePersonalized = this.product.productInfo.personalizations?.some((element) => {
                  return element.personalizedType === 'Name';
              });

              this.isNumberPersonalized = this.product.productInfo.personalizations?.some((element) => {
                   return element.personalizedType === 'Number';
              });
          this.quantityGroup.patchValue({
            isPersonalize: this.product.productInfo.isPersonalize,
            designerCartId: this.product.designerCartId,
          });
          this.getTotalQuantity();
          //this.getDecoGroup()
          this.getProductServices()
          this.getProductSizeAndFonts()
        },
        (error) => {
          // this.commonService.openErrorSnackBar(error.message, "");
        }
      );
  }

  // getAvailableStoreServices() {
  //   this.storeService.getAvailableStoreServices().subscribe(
  //     (response) => {
  //       this.serviceArray = response.data;
  //     }
  //   );
  // }

  getDirectProductDetail(productId, typeId) {
    this.commonService.toggleLoading(true)
    this.storeService.getDirectProductDetail(productId, typeId).subscribe(
      // this.storeService.getDirectProductDetail(5726, typeId).subscribe(
      (response) => {
        this.personalizeValue = true;
        this.product = response.data;
        localStorage.setItem("productId", this.product.productInfo.id);
        if (this.selectedColor == 0)
          this.selectedColor = this.product.productInfo.variants[0].id;
        this.quantityGroup.patchValue({
          isPersonalize: this.product.productInfo.isPersonalize,
        });
        if (!this.isEdit && this.purchaseType == 2)
          this.createDecorationProperties(this.product)
        this.getTotalQuantity();
       // this.getDecoGroup()
        this.getProductServices()
        this.getProductSizeAndFonts()
        this.commonService.postHeightToParent("medium")
        window.parent.postMessage("update title-Product | " + (this.product.productInfo.displayName || this.product.productInfo.name), "*")
        this.commonService.toggleLoading(false)
      },
      (error) => {
        // this.commonService.openErrorSnackBar(error.message, "");
      }
    );
  }

  getCartDetails() {
    this.storeService.getCartDetails(this.cartId).subscribe(
      (response) => {
        this.cartItemGrids = this.quantityGroup.controls
          .cartItemGrids as UntypedFormArray;
        let itemGrid = response.data.cartItemGrids;
        // if (itemGrid[0].name != "" || itemGrid[0].number != "") {
        //   this.personalizeValue = true;
        // }
        this.selectedColor = itemGrid[0].colorId;
        for (let i = 0; i < itemGrid.length; i++) {
          this.cartItemGrids.push(this.updateItemGrid(itemGrid[i]));
        }
        let isAnyCartItemIsPersonalized=this.cartItemGrids.value.some(item => item.personalize === true);
        if(isAnyCartItemIsPersonalized==true && this.purchaseType==3){
          this.showPersonalizationValue=true
        }else{
          this.showPersonalizationValue=false
        }
        this.quantityGroup.patchValue({
          cartId: response.data.cartId,
        });
        this.setDecorationPropertiesForm(response.data)
        if (response.data.personalizationProperties && response.data.personalizationProperties.length){
          this.setPersonalizationPropertiesForm(response.data.personalizationProperties)
          //this.deleteRow(0);
          this.getTotalQuantity();
          this.commonService.postHeightToParent("medium")
        }
        this.deleteRow(0);
      },
      (error) => {
        this.isEdit = false;
      }
    );
  }

  addToCart() {
    this.scrollToTop()
    if (this.purchaseType == 2 && this.decorationPropertiesForm.valid == false) {
      Swal.fire({
        position: 'top',
        icon: 'warning',
        text: 'Please complete decoration settings to continue'
      })
      return
    }
    let { isPersonalizationValid, isPersonalized } = this.isPersonalized()


    // this.getTotalQuantity();
    if (this.productId && this.productId.split("-")[1])
      this.quantityGroup
        .get("productId")
        .setValue(this.productId.split("-")[0]);
    this.cartItemGrids = this.quantityGroup.controls.cartItemGrids as UntypedFormArray;
    for (var i = 0; i < this.cartItemGrids.controls.length; i++) {
      this.cartItemGrids.controls[i].value.colorId = this.selectedColor;
    }
    this.quantityGroup.value.serviceId = this.selectedServiceId;
    this.quantityGroup.value.isPersonalize = isPersonalized;
    if (this.quantityGroup.valid) {
      let payload = this.quantityGroup.value
      payload['calculatedPrice']=this.decoPrice
      let isConfirm = false;
      let userId = localStorage.getItem("userId");
      if (!userId) {
        window.parent.postMessage("open guest login", "*");
        window.parent.postMessage("action - add to cart", "*");
        return;
      }
      
      if (isPersonalized) {
        if (isPersonalizationValid) {
          let { namePersonalizationQuantity, numberPersonalizationQuantity } = this.quantityGroup.value
          let isNamePersonalizationPropertiesValid = false
          let isNumberPersonalizationPropertiesValid = false
          this.personalizationPropertiesForm.controls.forEach(control => {
            if (control.value.personalizationTypeId == 1 && control.valid)
              isNamePersonalizationPropertiesValid = true
            else if (control.value.personalizationTypeId == 2 && control.valid)
              isNumberPersonalizationPropertiesValid = true
          })
          if (this.purchaseType != 3 &&
            ((namePersonalizationQuantity && !isNamePersonalizationPropertiesValid) ||
              (numberPersonalizationQuantity && !isNumberPersonalizationPropertiesValid))) {
            Swal.fire({
              position: 'top',
              icon: 'warning',
              text: 'Please complete personalization settings to continue'
            })
            return
          }
          let result = Swal.fire({
            text: "Customer is responsible for the spelling of Personalization Text provided. Please review and confirm the names & numbers.",
            icon: "info",
            showCancelButton: true,
            cancelButtonText: "Go back and Verify",
            confirmButtonText: "Confirm and Continue",
            reverseButtons: true,
            position: "top",
          }).then((result) => {
            if (result.isConfirmed) {
              if (this.purchaseType != 3)
                payload['personalizationProperties'] = this.personalizationPropertiesForm.value
                this.commonService.toggleLoading(true)
                if (this.purchaseType == 2) {
                  this.decorationPropertiesForm.value.forEach(el => {
                    el.colorId=el.colorId.map(color => color?.id) })
                  payload['decorationProperties'] = this.decorationPropertiesForm.value
                }
              this.storeService.addToCart(payload).subscribe(
                (response) => {
                  this.commonService.toggleLoading(false)
                  this.product = response.data;
                  this.commonService.openSuccessSnackBar(response.message, "");
                  window.top.postMessage("cart count-" + response.data, "*")
                  if (this.teamStoreId == null || this.teamStoreId == 0) {
                    this.router.navigateByUrl("enduser/cart");
                  } else {
                    this.router.navigateByUrl("enduser/buyfromlivestore/cart");
                  }
                },
                (error) => {
                }
              );
            }
          });
        } else {
          this.commonService.openErrorSnackBar(
            "Enter personalization values for selected items",
            ""
          );
        }
      } else {
        payload['personalizationProperties'] = null
        if (this.purchaseType == 2) {
          this.decorationPropertiesForm.value.forEach(el => {
            el.colorId=el.colorId.map(color => color?.id) 
            el.colorCount=el?.colorId?.length })
          payload['decorationProperties'] = this.decorationPropertiesForm.value
        }
        this.storeService.addToCart(payload).subscribe(
          (response) => {
            this.product = response.data;
            window.top.postMessage("cart count-" + response.data, "*")
            this.commonService.openSuccessSnackBar(response.message, "");
            if (this.teamStoreId == null || this.teamStoreId == 0) {
              this.router.navigateByUrl("enduser/cart");
            } else {
              this.router.navigateByUrl("enduser/buyfromlivestore/cart");
            }
          },
          (error) => {
            // this.commonService.openErrorSnackBar(error.message, "");
          }
        );
      }
      // if (isConfirm) {
      //   this.storeService.addToCart(this.quantityGroup.value).subscribe(response => {
      //     this.product = response.data;
      //     this.commonService.openSuccessSnackBar(response.message, "");
      //     if (this.teamStoreId == null || this.teamStoreId == 0) {
      //       this.router.navigateByUrl('enduser/cart');
      //     } else {
      //       this.router.navigateByUrl('enduser/buyfromlivestore/cart');
      //     }
      //   },
      //     error => {
      //       // this.commonService.openErrorSnackBar(error.message, "");
      //     });
      // }
    } else {
      this.commonService.openErrorSnackBar(
        "Please fill all required fields",
        ""
      );
    }
  }

  backToStore() {
    if (this.purchaseType === 1 || this.purchaseType === 2) {
      //this.router.navigateByUrl("enduser/directpurchase/productlist");
      let url=this.wpUrl+'Product-List/?api=true&category=0&search=&orderby=id&order=asc&color=0'
      window.parent.location.href = url;
    } else {
      this.router.navigateByUrl(
        "enduser/buyfromlivestore/store/" + this.teamStoreId
      );
    }
  }

  // personalize(value) {
  //   this.cartItemGrids = this.quantityGroup.controls.cartItemGrids as FormArray;
  //   this.quantityGroup.patchValue({
  //     isPersonalize: value,
  //   });
  //   for (let i = 0; i <= this.cartItemGrids.length; i++) {
  //     this.cartItemGrids.controls[i].patchValue({
  //       name: null,
  //       number: null,
  //     });
  //   }
  // }

  // clearFormArray = (formArray: FormArray) => {
  //   while (formArray.length !== 0) {
  //     formArray.removeAt(0);
  //   }
  // };

  getPriceBySize(sizeId) {
    if (sizeId && this.product) {
      let sizeObj = this.product.productInfo.variants[0].sizeVariants.find(
        (size) => size.sizeId == sizeId
      );
      if (this.purchaseType == 3) {
        if (sizeObj?.storePrice)
          return sizeObj.storePrice + (this.product.productInfo.totalPrice - this.product.productInfo.price)
        else
          return this.calculateStorePrice(sizeObj.sellingPrice) + (this.product.productInfo.totalPrice - this.product.productInfo.price)
      } else {
        return sizeObj ? sizeObj.sellingPrice : 0
      }
    } else {
      return 0;
    }
  }

  calculateStorePrice(price) {
    price = Math.ceil(price);

    if (price < 10) price = price + 1;
    else price = price + 2;

    return price;
  }

  getTotalQuantity() {

    let cartItemGrids = this.quantityGroup.controls.cartItemGrids.value;
    // const basePrice = this.product ? this.purchaseType == 3 ? this.product.productInfo.totalPrice : this.product.productInfo.price : 0
    let totalQuantity = 0,
      totalPrice = 0,
      namePersonalizationQuantity = 0,
      numberPersonalizationQuantity = 0,
      personalizedQuantity = 0
    // personalizationPrice = 0
    for (let i = 0; i < cartItemGrids.length; i++) {
      let price = this.getPriceBySize(cartItemGrids[i].size);
      // let personalization = 0;
      let personalizationType = 0;
      if (cartItemGrids[i].personalize) {
        if (cartItemGrids[i].name) {
          // personalization += 7;
          personalizationType = 1;
          namePersonalizationQuantity += Number(cartItemGrids[i].quantity)
        } else {
          personalizationType = 0;
        }
        if (
          cartItemGrids[i].number != null &&
          cartItemGrids[i].number.toString().trim()
        ) {
          numberPersonalizationQuantity += Number(cartItemGrids[i].quantity)
          // personalization += 6;
          if (personalizationType == 1) {
            personalizationType = 3;
          } else {
            personalizationType = 2;
          }
        }
        personalizedQuantity += Number(cartItemGrids[i].quantity);
      }

      // this.quantityGroup.controls.cartItemGrids["controls"][i]
      //   .get("totalPrice")
      //   .setValue(
      //     Number(cartItemGrids[i].quantity) * (price + personalization)
      //   );

      // this.quantityGroup.controls.cartItemGrids["controls"][i]
      //   .get("personalizationPrice")
      //   .setValue(Number(cartItemGrids[i].quantity) * personalization);

      this.quantityGroup.controls.cartItemGrids["controls"][i]
        .get("personalizationType")
        .setValue(personalizationType);

      totalQuantity += Number(cartItemGrids[i].quantity);

      // personalizationPrice +=
      //   Number(cartItemGrids[i].quantity) * personalization;

      totalPrice +=
        Number(cartItemGrids[i].quantity) * (price); // + personalization
    }

    this.quantityGroup.patchValue({
      quantity: totalQuantity,
      price: totalPrice.toFixed(2),
      namePersonalizationQuantity,
      numberPersonalizationQuantity
      // totalPrice,
      // personalizationPrice,
      // personalizedQuantity,
    });
    this.getDecoPrice(true)
    this.commonService.postHeightToParent("medium")
  }

  onImgClick(src) {
    // $(".img-holder").attr("src", src);
    $(".img-container").css('background-image', 'url(' + src + ')')

  }

  onPersonalizeCheck(i) {
    let formGroup = this.quantityGroup.get("cartItemGrids")["controls"][i];
    if (formGroup.controls.personalize.value) {
      formGroup.controls.name.enable();
      formGroup.controls.number.enable();
      if(this.product.productInfo.isPersonalize==true && this.purchaseType==3){
        this.showPersonalizationValue=true
      }else{
        this.showPersonalizationValue=false
      }
    } else {
      formGroup.controls.name.setValue("");
      formGroup.controls.number.setValue("");
      formGroup.controls.name.disable();
      formGroup.controls.number.disable();
      this.showPersonalizationValue=false
    }
    this.commonService.postHeightToParent("medium")
  }

  isPersonalized() {
    let isPersonalized = false;
    let isPersonalizationValid = true;
    let isNamePersonalized=false
    let isNumberPersonalized=false
    if(this.product==undefined || this.product?.productInfo?.personalizations==null){
      isNamePersonalized=true
      isNumberPersonalized=true
    }
    else{
      isNamePersonalized=this.isNamePersonalized?this.isNamePersonalized:false
      isNumberPersonalized=this.isNumberPersonalized?this.isNumberPersonalized:false
    }
    this.quantityGroup.get("cartItemGrids")["controls"].forEach((element) => {
      if (element.value.personalize) {
        isPersonalized = true;
        if (!element.value.name && !element.value.number)
          isPersonalizationValid = false;

        if(this.isNumberPersonalized)  
          {
            if(element.value.number<=0){
              isPersonalizationValid=true;
            }
          }

      }
    });
    return { isPersonalizationValid, isPersonalized , isNamePersonalized,isNumberPersonalized  }
  }

  // getPersonalizationServices() {
  //   this.sharedServices.getPersonalizationServices({ internal: false }).subscribe((res: any) => {
  //     this.personalizationServices = res.data
  //   })
  // }

  // getPersonalizationTypes() {
  //   this.storeService.getPersonalizationTypes().subscribe(res => {
  //     this.personalizationTypes = res.data
  //     this.createPersonalizationPropertiesForm()
  //   })
  // }

  // getAvailableInkColors() {
  //   this.storeService.getAvailableInkColors().subscribe((response) => {
  //     this.inkColorArray = response.data;
  //   });
  // }

  getInkColorsByService(i) {
    let serviceId = this.personalizationPropertiesForm.value[i].serviceId
    if (serviceId&&this.decoGroupServices)
      return this.decoGroupServices.find(color => color.decoGroupMethodId == serviceId).colors
    return []
  }

  getFontByService(i) {
    let serviceId = this.personalizationPropertiesForm.value[i].serviceId
    let typeId = this.personalizationPropertiesForm.value[i].personalizationTypeId
    if (serviceId && typeId && this.decoProductSizeAndFonts) {
      let personalizationTypes = this.decoProductSizeAndFonts.find(service => service.id == serviceId).personalizationTypes
      if (personalizationTypes && personalizationTypes.length) {
        let type = personalizationTypes.find(type => type.id == typeId)
        if (type)
          return type.fonts
      }
    }
    return []
  }


  getSizesByService(i) {
    try {
      let serviceId = this.personalizationPropertiesForm.value[i].serviceId
      let typeId = this.personalizationPropertiesForm.value[i].personalizationTypeId
      if (serviceId && typeId) {
        let personalizationTypes = this.decoProductSizeAndFonts.find(service => service.id == serviceId).personalizationTypes
        if (personalizationTypes && personalizationTypes.length) {
          let type = personalizationTypes.find(type => type.id == typeId)
          if (type)
            return type.sizes
        }
      }
      return []
    } catch (error) {
      return []
    }
  }

  // getDecoGroup() {
  //   this.productService.getDecoGroup(this.product.productInfo.decoGroupId).subscribe(res => {
  //     this.decoGroup = res.body.data
  //   })
  // }
  getProductServices() {
    this.commonService.toggleLoading(true)
    this.productService.getProductServices(this.product.productInfo.decoGroupMethodId).subscribe(res => {
      this.decoGroupServices = res.body.data
    this.commonService.toggleLoading(false)
    },
    error => {
    this.commonService.toggleLoading(false)
    })
  }
  getProductSizeAndFonts(){
    this.commonService.toggleLoading(true)
    this.productService.getProductSizeAndFonts(this.product.productInfo.decoGroupMethodId).subscribe(res => {
      this.decoProductSizeAndFonts = res.body.data
      if(!this.personalizationPropertiesForm.controls.length){
      this.createPersonalizationPropertiesForm() }
      this.commonService.toggleLoading(false)
    },
    error => {
    this.commonService.toggleLoading(false)
    })
  }
  createDecorationProperties(designedOutput) {
    // sides.forEach(side => {
    //   if (side.is_designed) {
    //     side.assets_doc_path.forEach(art => {
    //       this.decorationPropertiesForm.push(this.createDecorationPropertyGroup({ art }))
    //     })
    //   }
    // })
    designedOutput.sideInfo.forEach((art,index) => {
    this.decorationPropertiesForm.push(this.createDecorationPropertyGroup( art,index,designedOutput ))
    })
  }
  createDecorationPropertyGroup2(obj,index,productDetails) {
    return this.formBuilder.group({
      art: obj?.art,
      id: obj?.id || 0,
      //positionId: this.formBuilder.control(obj.positionId || '', Validators.required),
      decoGroupMethodId : this.formBuilder.control(productDetails?.decoGroupMethodId|| '', Validators.required),
      serviceName:productDetails.decoGroupMethod,
      //sizeId: this.formBuilder.control(obj.sizeId || ''),
      serviceId: this.formBuilder.control(productDetails.decoGroupMethodId || ''),
      colorId: this.formBuilder.control(obj?.colorInfo || [], Validators.required),
      colorCount: this.formBuilder.control(obj.colorCount || '', Validators.required)
    })
  }
  createDecorationPropertyGroup(obj,index,productDetails) {
    return this.formBuilder.group({
      art: obj.imageUrl,
      id: 0,
      //positionId: this.formBuilder.control(obj.positionId || '', Validators.required),
      decoGroupMethodId: this.formBuilder.control(productDetails.productInfo.decoGroupMethodId || '', Validators.required),
      serviceName:productDetails?.productInfo?.decoGroupMethod,
      //sizeId: this.formBuilder.control(obj.sizeId || ''),
      serviceId: this.formBuilder.control(productDetails?.productInfo?.decoGroupMethodId|| ''),
      colorId: this.formBuilder.control(obj.colorInfo|| [], Validators.required),
      colorCount: this.formBuilder.control(obj.colorCount || '', Validators.required)
    })
  }

  getDecoServices(i, type = 'deco') {
    if (type == 'deco') {
      var positionId = this.decorationPropertiesForm.value[i].positionId
    } else {
      var positionId = this.personalizationPropertiesForm.value[i].positionId
    }
    if (positionId && this.decoGroup) {
      return this.decoGroup.locations.find(loc => loc.id == positionId).services
    }
    return []
  }

  getDecoInkColors(i) {
    let serviceId = this.decorationPropertiesForm.value[i].serviceId
    if (serviceId && this.inkColorArray)
      return this.inkColorArray.find(color => color.serviceId == serviceId).colors
    return []
  }

  updateColorCount(i) {
    this.decorationPropertiesForm.controls[i].get('colorCount')
      .setValue(this.decorationPropertiesForm.value[i].colorId.length)
  }

  zoomIn(event) {
    let pre = document.getElementsByClassName("img-container")[0] as HTMLDivElement
    let posX = event.offsetX * 2
    let posY = event.offsetY * 2
    pre.style.backgroundSize = '300%'
    pre.style.backgroundPosition = (-posX) + "px " + (-posY) + "px";
  }
  // zoomIn(event) {
  //   let pre = document.getElementById("zoom-preview");
  //   pre.style.display = "inline";
  //   let src = event.target.currentSrc
  //   pre.style.backgroundImage = `url('${src}')`;
  //   let posX = event.offsetX + Math.trunc((event.offsetX / 100) * 40);
  //   let posY = event.offsetY + Math.trunc((event.offsetY / 100) * 40);
  //   pre.style.backgroundPosition = (-posX) + "px " + (-posY) + "px";
  // }

  zoomOut() {
    let pre = document.getElementsByClassName("img-container")[0] as HTMLDivElement
    pre.style.backgroundPosition = 'center'
    pre.style.backgroundSize = 'contain'
  }
  // zoomOut() {
  //   let pre = document.getElementById("zoom-preview");
  //   pre.style.display = "none";
  // }

  getDecoPrice(isSilent?) {
    let { isPersonalized } = this.isPersonalized()
    let isNamePersonalizationPropertiesValid = false
    let isNumberPersonalizationPropertiesValid = false
    this.personalizationPropertiesForm.controls.forEach(control => {
      if (control.value.personalizationTypeId == 1 && control.valid)
        isNamePersonalizationPropertiesValid = true
      else if (control.value.personalizationTypeId == 2 && control.valid)
        isNumberPersonalizationPropertiesValid = true
    })
    let isDesigned = this.purchaseType == 2
    let data = []
    let totalQuantity = this.quantityGroup.get('quantity').value
    this.decoPrice = null

    let storeData = {
      "storeId": this.teamStoreId,
      "mapCode": this.mapCode,
      "personalizationNameQty": 0,
      "personalizationNumberQty": 0,
      "totalQty": totalQuantity
    }

    if (isDesigned) {
      let decoPropertyArr = this.decorationPropertiesForm.value
      // const { colorId, ...rest } = this.decorationPropertiesForm.value;
      //  const newVariable = { ...rest, colorId: colorId.map(color => color.id) };

       decoPropertyArr.forEach(el => {
        if(el.serviceName=='Embroidery'){
          el.colorCount=0
        }
         let ids=el.colorId.map(color => color?.id)
         //const commaSeparatedColorIds: number = ids.join(',');
        // el.colorId=ids
        data.push(this.createDecoPriceApiObj(
          totalQuantity,
          el.colorCount,
          3, // itemType - color
          el.serviceId,
          ids
        ))
      })
    }

    if (isPersonalized) {
      let personalizationPropertyArr = this.personalizationPropertiesForm.value
      personalizationPropertyArr.forEach(el => {
        if (el.personalizationTypeId == 1 || el.personalizationTypeId == 3)
          storeData.personalizationNameQty += this.quantityGroup.get('namePersonalizationQuantity').value
        if (el.personalizationTypeId == 2 || el.personalizationTypeId == 3)
          storeData.personalizationNumberQty += this.quantityGroup.get('numberPersonalizationQuantity').value
        let count = el.personalizationTypeId == 0 ? 0 :
          el.personalizationTypeId == 1 ?
            this.quantityGroup.get('namePersonalizationQuantity').value :
            this.quantityGroup.get('numberPersonalizationQuantity').value // if type = 3 both qty are same
        if (count) {
          let sizeid
          if(el.serviceName=='Heatpress' || el.serviceName=='Print'){
            sizeid=0
          }else{
            sizeid= el.sizeId
          }
          data.push(this.createDecoPriceApiObj(
            Number(count),
            sizeid,
            el.personalizationTypeId, // itemType - 1,2
            el.serviceId,
            [el.colorId]
          ))
        }
      });
    }

    if (this.purchaseType == 3 && totalQuantity) {
      this.decoPrice = {
        decoPrice: 0,
        namePrice: 7,
        numberPrice: 5,
      }
      // this.sharedServices.getStoreDecoPrice(storeData).subscribe(res => {
      //   this.decoPrice = res.data
      // })
      // return
    } else {
      if (!isDesigned && !isPersonalized) {
        // this.quantityGroup.get('decoPrice').setValue(0)
        // return
        this.decoPrice = {
          decoPrice: 0,
          namePrice: 0,
          numberPrice: 0,
        }
      } else if (isDesigned && this.decorationPropertiesForm.valid == false) {
        if (!isSilent)
          this.commonService.openErrorSnackBar("Please complete decoration settings to calculate total price", "")
        // this.quantityGroup.get('decoPrice').setValue(0)
        return
      } else if (isPersonalized &&
        ((storeData.personalizationNameQty && !isNamePersonalizationPropertiesValid) ||
          (storeData.personalizationNumberQty && !isNumberPersonalizationPropertiesValid))
      ) {
        if (!isSilent)
          this.commonService.openErrorSnackBar("Please complete personalization settings to calculate total price", "")
        // this.quantityGroup.get('decoPrice').setValue(0)
        return
      } else if (!totalQuantity) {
        // this.quantityGroup.get('decoPrice').setValue(0)
        this.decoPrice = {
          decoPrice: 0,
          namePrice: 0,
          numberPrice: 0,
        }
        // return
      } else if (!data.length) {
        this.decoPrice = {
          decoPrice: 0,
          namePrice: 0,
          numberPrice: 0,
        }
        // return
      } else {
        this.decoPrice = {
          decoPrice: 0,
          namePrice: 0,
          numberPrice: 0,
        }
        this.decoPriceLoading = true
        this.commonService.toggleLoading(true)
        this.sharedServices.getDecoPriceByProperties(data).subscribe(res => {
          this.decoPriceLoading = false
          this.commonService.toggleLoading(false)
          this.decoPrice = res.data
        })
      }
    }
    this.calculateTotalPrice()
  }

  createDecoPriceApiObj(count, itemValue, itemType, serviceId, colorIds) {
    return { count, itemValue, itemType, serviceId, colorIds }
  }

  isDecorated() {
    if (
      this.isPersonalized().isPersonalized ||
      this.purchaseType == 2
    ) {
      return true
    }
    return false
  }

  calculateTotalPrice() {
    // if (this.purchaseType == 3) {
    //   this.decoPrice = {
    //     namePrice: 0,
    //     numberPrice: 0,
    //     decoPrice: 0,
    //   }
    // }
    let cartItemGrids = this.quantityGroup.controls.cartItemGrids;
    let totalPrice = 0
    for (let i = 0; i < cartItemGrids.value.length; i++) {
      if (!cartItemGrids.value[i].size || !cartItemGrids.value[i].quantity)
        continue
      let basePrice = this.getPriceBySize(cartItemGrids.value[i].size)
      let quantity = cartItemGrids.value[i].quantity
      let personalizationPrice = 0

      let nameCount=0
      let numberCount=0
      if(this.product?.productInfo?.personalizations){
        let pTypes = this.product.productInfo.personalizations.map(item => item.personalizedType);
        nameCount = pTypes.filter(personalizedType => personalizedType == 'Name').length;
        numberCount = pTypes.filter(personalizedType => personalizedType == 'Number').length;
      }
      if (cartItemGrids.value[i].personalizationType == 3 || cartItemGrids.value[i].personalizationType == 1) {
        cartItemGrids['controls'][i].get('personalizationNamePrice').setValue(quantity * this.decoPrice.namePrice)
        if(nameCount==0){
          nameCount=1
        }
        personalizationPrice += (quantity * (this.decoPrice.namePrice *nameCount))
      }
      if (cartItemGrids.value[i].personalizationType == 3 || cartItemGrids.value[i].personalizationType == 2) {
        cartItemGrids['controls'][i].get('personalizationNumberPrice').setValue(quantity * this.decoPrice.numberPrice)
        if(numberCount==0){
          numberCount=1
        }
        personalizationPrice += (quantity * (this.decoPrice.numberPrice *numberCount))
      }
      if (!cartItemGrids.value[i].personalizationType) {
        cartItemGrids['controls'][i].get('personalizationNamePrice').setValue(0)
        cartItemGrids['controls'][i].get('personalizationNumberPrice').setValue(0)
      }
      cartItemGrids['controls'][i].get('decoPrice').setValue(quantity * this.decoPrice.decoPrice)
      cartItemGrids['controls'][i].get('totalPrice').setValue(
        (quantity * (this.decoPrice.decoPrice + basePrice)) + personalizationPrice
      )
      totalPrice += ((quantity * (this.decoPrice.decoPrice + basePrice)) + personalizationPrice)
    }
    this.quantityGroup.get('totalPrice').setValue(totalPrice.toFixed(2))
    if (this.decoPrice)
      this.quantityGroup.get('decoPrice').setValue(this.decoPrice.decoPrice * this.quantityGroup.value.quantity)
      if(this.quantityGroup.value.quantity>0){
         this.unitPrice=(this.product?.productInfo?.variants[0].sizeVariants[0].sellingPrice + this.decoPrice?.decoPrice).toFixed(2)
      }else{
        this.unitPrice=0
      }
    return totalPrice.toFixed(2)
  }

  onDecoLocationChange(i, type) {
    if (type == 'deco') {
      this.decorationPropertiesForm.controls[i].patchValue({
        serviceId: '',
        sizeId: '',
        colorId: '',
        colorCount: '',
      })
    } else {
      this.personalizationPropertiesForm.controls[i].patchValue({
        serviceId: '',
        sizeId: '',
        colorId: '',
        fontId: '',
        serviceName:''
      })
    }
  }

  onDecoServiceChange(i, type) {
    if (type == 'deco') {
      this.decorationPropertiesForm.controls[i].patchValue({
        sizeId: '',
        colorId: '',
        colorCount: '',
      })
    } else {
      this.personalizationPropertiesForm.controls[i].patchValue({
        sizeId: '',
        colorId: '',
        fontId: '',
        serviceName:''
      })
    }
  }

  isDecoLocationSelected(id) {
    let usedPositions = []
    this.decorationPropertiesForm.controls.forEach(control => {
      if (control.value.positionId)
        usedPositions.push(control.value.positionId)
    })
    this.personalizationPropertiesForm.controls.forEach(control => {
      if (control.value.positionId)
        usedPositions.push(control.value.positionId)
    })
    return usedPositions.includes(id)
  }

  isNumber(event) {
    console.log(event)
    return false
  }

  isAlphabet() {

    return false
  }

  scrollToTop() {
    this.commonService.postScrollMessageToParent()
  }
  copyUrl(){
    // const parentURL =window.parent.location.href+"&teamStoreId="+this.teamStoreId+"&purchaseType="+this.purchaseType
    // clipboardy.write(parentURL);

  const currentUrl = this.router.url;
  let updatedUrl=''
  if(this.purchaseType==2){
    const queryParams = "&isCopiedDesignedProductUrl=true"+"&teamStoreId="+this.teamStoreId+"&purchaseType="+this.purchaseType;
      updatedUrl = currentUrl + queryParams;
      this.router.navigateByUrl(updatedUrl);
  }
  this.isCopied=true
  }
}
