import { Component, DebugEventListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { StoreService } from 'src/app/core/services/store.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  cartItems: any;
  cartGridItems: any[] = [];
  totalAmount: any = 0;
  totalNumberOfProducts: number = 0;
  noImagePlaceholder = "../../../../assets/images/default-image.jpg"

  constructor(
    private route: ActivatedRoute,
    private storeService: StoreService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    let userId = this.route.snapshot.params['userId']
    console.log(userId);    
    this.getCartList(userId);
  }

  getCartList(userId) {
    this.storeService.getCartListByUserId(userId).subscribe(response => {
      this.cartItems = response.data;
      this.cartItems.cartItemsInfo = this.cartItems.cartItemsInfo.map(v => Object.assign(v, { selected: true }))
      this.totalAmount = this.calculateTotalAmount(this.cartItems.cartItemsInfo);
      this.commonService.postHeightToParent("medium")
      window.top.postMessage("cart count-" + this.cartItems.cartTotal, "*");
    },
      error => {
        this.cartItems = {};
        this.cartItems.cartItemsInfo = [];
        this.totalAmount = 0;
        this.commonService.postHeightToParent("medium")
      });
  }

  calculateTotalAmount(cartItemsInfo) {
    let totalAmount = 0;
    cartItemsInfo.forEach(item => {
      totalAmount += item.totalPrice;
    });
    return totalAmount.toFixed(2);
  }

}
