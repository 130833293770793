import { Component, ElementRef, Inject, ViewChild } from '@angular/core'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-response-window',
  templateUrl: './response-window.component.html',
  styleUrls: ['./response-window.component.scss']
})

export class ResponseWindowComponent {
  graduationForm: FormGroup;
  private yearChanges: Subscription;
  constructor(
    public dialogRef: MatDialogRef<ResponseWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    
    private router: Router,private fb: FormBuilder)
    {
      this.YearForm()

      this.yearChanges = this.graduationForm.get('year').valueChanges.pipe(
        debounceTime(100) // Adjust the debounce time as needed
      ).subscribe(newValue => {
        const newVal = newValue.replace(/[^0-9]/g, ''); 
        this.graduationForm.get('year').setValue(newVal);
      });
       
    }
    isChecked = true;

  onCheckboxChange(event: Event) {
    this.isChecked = (event.target as HTMLInputElement).checked;
  }
  ngOnDestroy() {
    this.yearChanges.unsubscribe();
  }
  ngOnInit(): void { 
  }
  YearForm(){
    this.graduationForm = this.fb.group({
      year: ['', [Validators.required,Validators.minLength(4), Validators.pattern(/^[0-9]$/)]], 
      keepUpdated: [true],
    });
  }
  
  OkClicked(){
    if (this.data.isEmailConfirmed){
      if (this.router.url.includes('/storemanager/bulkorderentrylist') || this.router.url.includes('/bulkOrderDetails') ) { 
      }else{
        this.router.navigate(["/enduser/useraccount/orderhistory"]);
      }
    }
    else{
       window.top.postMessage("goto home", "*")
       
    }
    this.dialogRef.close();  
  }
  CompleteReg(){
    window.top.postMessage("open guest signup", "*")
       window.parent.postMessage("action - goto profile", "*");
  }
  TryAgain(){
    this.dialogRef.close();  
  }
}
