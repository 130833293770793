<!-- Success -->
<div style="overflow-x: hidden;">


    <div class="row"> 
        <!-- <div class="col-md-12 before_sucess-container" *ngIf="data.icon=='success' && !data.isUserClickedOkButton ">
            <form [formGroup]="graduationForm" class="before_sucess-msg">
                <label style="margin-bottom: 1.5rem;" for="graduationYear">What year in the future does the student/athlete graduate HS or College?</label>
                <div class="formGroup">
                    <input [ngClass]="{ 'invalid-info': graduationForm.get('year').hasError('required')==true && graduationForm.get('year').touched==true  && graduationForm.get('year').hasError('minlength')}" type="text" formControlName="year" maxlength="4" min="0" max="9" required>
                  </div>
                  <div style="margin-top: .5rem; color: #e90000;" *ngIf="graduationForm.get('year').hasError('required') && graduationForm.get('year').touched">
                    Year is required.
                  </div>
                  <div style="margin-top: .5rem; color: #e90000;" *ngIf="graduationForm.get('year').hasError('minlength') && graduationForm.get('year').touched">
                    Enter a valid Year.
                  </div>
                <label style="margin-top: 1.5rem;">
                  <input type="checkbox" formControlName="keepUpdated"> Please keep me updated on any other events/sales associated with my team
                </label>
              
                <button (click)="data.isUserClickedOkButton=true" class="sucess-btn" type="submit">Submit</button>
              </form>
        </div> --> 
        <!-- && data.isUserClickedOkButton -->
        <div class="col-md-12 sucess-container" *ngIf="data.icon=='success' ">
            <div class="smiley-image-div">
                <img src="../../../assets/icon/Sucess.png" alt="">
            </div>
            <h1>Success!</h1>
            <div class="sucess-msg">
                <p>Your order has been placed and is being processed.</p> 
            </div>
            <button (click)="OkClicked()" class="sucess-btn"> 
                 Ok 
            </button>
            <button *ngIf="!data.isEmailConfirmed" (click)="CompleteReg()" class="reg-btn">
                Complete Registration
            </button>
        </div>
    </div>


    <!-- Success -->

    <!-- failed -->
    <div class="row">
    <div *ngIf="data.icon=='error'" class="col-md-12  oh-snap-container">
        <div class="smiley-image-div">
            <img src="../../../assets/icon/Oh-Snap.png" alt="">
        </div>
        <h1>Oh Snap!</h1>
        <div class="snap-msg">
            <!-- <p>Looks like something went wrong while processing your request.</p> -->
            <p>{{this.data?.text}}</p>
            <p>Sorry we were unable to process your order. Please confirm your details and try again, you may need a different form of payment.</p>
        </div>
        <button (click)="TryAgain()" class="snap-btn">
            Try again 
        </button>
    </div>
    </div>
    <!-- failed -->
</div>