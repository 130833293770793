import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { StoreService } from 'src/app/core/services/store.service';
declare var $: any
import Swal from 'sweetalert2';
import { setTimeout } from 'timers';

@Component({
  selector: 'app-layout1-preview',
  templateUrl: './layout1.component.html',
  styleUrls: ['./layout1.component.scss']
})
export class Layout1Component implements OnInit {

  constructor(
    private storeService: StoreService,
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
  ) { }

  @Input() layoutData: any;
  @Input() teamStoreId: number;
  @Input() teamStoreData: any;
  @Output() eventType: EventEmitter<any> = new EventEmitter();
  @Output() isStoreVerified: EventEmitter<number> = new EventEmitter()

  productsByCategory = []
  noImagePlaceholder = "../../../../../assets/images/default-image.jpg"
  selectedImage = []
  rejectProductGroup: UntypedFormGroup
  rejectStoreGroup: UntypedFormGroup
  referenceFiles = []
  rejectionCodes
  states = []
  isAfterVerification=false

  ngOnInit() {
    this.getCatagoryWiseProducts()
    this.createProductRejectGroup()
    this.createStoreRejectGroup()
    this.getTeamStore()
    this.getStoreRejectionCodes()
    this.getStates()
  }

  getCatagoryWiseProducts() {
    this.storeService.getCategoriesWithProducts(this.teamStoreId).subscribe(
      (response) => {
        this.productsByCategory = response.data;
        let userVerified: number = this.productsByCategory[0] ? this.productsByCategory[0].isUserVerified : 0;
        // this.isStoreVerified.emit(userVerified);
        for (let i = 0; i < this.productsByCategory.length; i++) {
          this.selectedImage[i] = []
        }
      },
      (error) => {
        console.log(error)
      });
  }
  mapFn(items) {
    const personalizedTypes = Array.from(new Set(items.map(item => item.personalizedType)));
    return personalizedTypes.join(', ');
  }
  clickEvent(type, value, colorId = null, mapCode) {
    let event = {
      type, value, colorId, mapCode
    }
    this.eventType.emit(event);
  }

  approveProduct(mapCode) {
    let obj = {
      "teamstoreGarmentStatus": 1,
      "rejectionText": "Approved",
      "rejectionCodes": "",
      "mapCode": mapCode
    }
    this.updateProductStatus([obj])
  }

  resetProductStatus(mapCode) {
    if(this.teamStoreData.stage!=9 && this.isAfterVerification==false || this.teamStoreData.stage == 20 || this.teamStoreData.stage == 19 && !this.isCloseDatePassed(this.teamStoreData?.closeDate) )
      return
      
    let obj = {
      "teamstoreGarmentStatus": 0,
      "rejectionText": "",
      "rejectionCodes": "",
      "mapCode": mapCode
    }
    this.updateProductStatus([obj])
  }

  updateProductStatus(arr) {
    this.storeService.updateProductStatus(this.teamStoreData.id, arr).subscribe((res: any) => {
      // this.getCatagoryWiseProducts()
      this.productsByCategory = res.data;
      this.commonService.openSuccessSnackBar("Product Status Updated", "")
    }, err => {
      console.log(err)
      this.commonService.openErrorSnackBar("Some Error Occurred", "")
    })
  }

  createProductRejectGroup() {
    this.rejectProductGroup = this.fb.group({
      teamstoreGarmentStatus: 2,
      rejectionText:  ["", Validators.required],
      rejectionCodes: ["", Validators.required],
      mapCode: 0,
    })
  }

  createStoreRejectGroup() {
    this.rejectStoreGroup = this.fb.group({
      rejectionReason: ["", Validators.required],
      rejectionCode: ["", Validators.required],
    })
    this.updateRejectionReasonValidation();
  }

  updateRejectionReasonValidation() {
    const rejectionReasonControl = this.rejectStoreGroup.get('rejectionReason');
  
    if (this.isAnyRejected()) {
      rejectionReasonControl.clearValidators(); // Remove required validator
    } else {
      rejectionReasonControl.setValidators(Validators.required); // Add required validator
    }
  
    rejectionReasonControl.updateValueAndValidity(); // Update the validation status
  }
  
  getTeamStore() {
    this.storeService.getStore(this.teamStoreId).subscribe(res => {
      this.teamStoreData = res.data
      if(!this.isCloseDatePassed(this.teamStoreData?.closeDate)){
      if (this.teamStoreData.stage === 20) {
        this.teamStoreData.stage = 20
        Swal.fire({
          title: "Approved",
          text: "You have already approved this store build. Would you like to make changes ?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        }).then((result) => {
          if (result.isConfirmed) {
            this.isAfterVerification=true
            this.teamStoreData.stage = 9;
             
          } else if (result.isDismissed) { 
            $("#infoModal").modal("hide");
          }
        });
      } else if (this.teamStoreData.stage === 19) {
        this.teamStoreData.stage = 19
        Swal.fire({
          title: "Rejected",
          text: "You have rejected the store build.",
          icon: "success",
        })
      }
      else if (this.teamStoreData.stage === 10) {
        this.teamStoreData.stage = 19
        Swal.fire({
          text: "This Store is already Live !",
          icon: "success",
        })
      } else {
          $("#infoModal").modal("show")
      }}else{
        Swal.fire({
          text: "This Store is Closed !",
          icon: "info",
        })
      }
    })
  }

  showProductRejectPopup(mapCode) {
    this.rejectProductGroup.get('mapCode').setValue(mapCode)
    $('#rejectProductModal').modal('show')
  }

  closePopup() {
    $('.modal').modal('hide')
    this.createProductRejectGroup()
    this.createStoreRejectGroup()
  }

  getStoreRejectionCodes() {
    this.storeService.getTeamStoreRejectionCodes().subscribe(
      (response) => {
        this.rejectionCodes = response.data;
      }
    );
  }

  rejectProduct() {
    let obj = this.rejectProductGroup.value
    obj['rejectionCodes'] = this.rejectProductGroup.get('rejectionCodes').value.toString()
    this.updateProductStatus([obj])
    this.closePopup()
  }

  showRejectStoreModal() {
    this.createStoreRejectGroup()
    $('#rejectStoreModal').modal('show')
  }

  showStoreApproveAlert() {
    this.createStoreRejectGroup()
    Swal.fire({
      icon: 'warning',
      title: 'Approve Store',
      html: `
      <p>Please double-check as, once approved, we cannot make changes:</p>
      <ul>
      <li>Please verify Art is correct.</li>
      <li>Please verify you got the fundraising amount you want.</li>
      <li>Please make sure the shipping method is as desired.</li>
      <li>If everything looks correct, please approve and let's move forward.</li>
      <li>If something is not as you wished, please let us know so we can fix it.</li>
      </ul>
      <style> p, ul {  text-align: left;  }  ul { list-style-type: none; padding-left: 20px; } </style>`,
      confirmButtonText: 'Approve',
      showCancelButton: true,
      cancelButtonText: 'Go Back',
      width: '630px'
    }).then(result => {
      if (result.isConfirmed)
        this.submitStoreStatus(2)
    })
  }

  rejectStore() {
    this.submitStoreStatus(1)
  }

  submitStoreStatus(status) {
    let data = this.rejectStoreGroup.value
    data['rejectionCode'] = this.rejectStoreGroup.value.rejectionCode.toString()
    data['referenceFiles'] = this.referenceFiles
    this.closePopup()
    this.storeService.userVerifyTeamStore(this.teamStoreId, status, data).subscribe(res => {
      if (status === 2) {
        this.teamStoreData.stage = 20
        Swal.fire({
          title: "Approved",
          text: "You have approved the store build.",
          icon: "success",
        })
      } else if (status === 1) {
        this.teamStoreData.stage = 19
        Swal.fire({
          title: "Rejected",
          text: "You have rejected the store build.",
          icon: "success",
        })
      }
      this.getCatagoryWiseProducts()
    })
  }

  get showStoreRejectButton() {
    for (let cat of this.productsByCategory) {
      if (cat.approvedProducts + cat.rejectedProducts != cat.totalProducts)
        return false
    }
    return true
  }

  get showStoreApproveButton() {
    for (let cat of this.productsByCategory) {
      if (cat.approvedProducts != cat.totalProducts)
        return false
    }
    return true
  }

  uploadReference(files) {
    const formData = new FormData();
    formData.append("file", files.item(0));
    if (files.item(0).name.split(".").pop().toLowerCase() == "pdf") {
      this.commonService.toggleLoading(true)
      this.storeService.uploadPdfArt(formData).subscribe((response) => {
        this.commonService.toggleLoading(false)
        response.data.imageFileUrls.forEach((url) => {
          this.referenceFiles.push(url);
        });
      }, err => {
        this.commonService.toggleLoading(false)
      });
    } else if (['jpg', 'png', 'jpeg', 'gif', 'webp', 'jfif'].includes(files.item(0).name.split(".").pop().toLowerCase())) {
      this.commonService.toggleLoading(true)
      this.storeService.uploadArt(formData).subscribe((response) => {
        this.commonService.toggleLoading(false)
        this.referenceFiles.push(response.data);
      }, err => {
        this.commonService.toggleLoading(false)
      });
    } else {
      this.commonService.openErrorSnackBar("Invalid file format", "")
    }
  }

  removeReferenceFile(i) {
    this.referenceFiles.splice(i, 1)
  }

  getState(id) {
    if (this.states.length) {
      let foundState = this.states.find(
        (state) => Number(state.id) == Number(id)
      );
      if (foundState) return foundState;
      else return {};
    } else {
      return {};
    }
  }

  getStates() {
    this.commonService.getAllStates().subscribe((res) => {
      this.states = res.data;
    });
  }
  isAnyRejected(){
    return  this.productsByCategory.some(x=>x.rejectedProducts > 0) ? true : false ;
  }

  isCloseDatePassed(closeDateString: string): boolean { 
    const closeDate = new Date(closeDateString); 
    const currentDate = new Date(); 
    return closeDate < currentDate;
  }
}
