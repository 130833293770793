<div class="w-100 text-center" *ngIf="loading" style=" top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;" >
  <div class="spinner-border" style="width: 6rem; height: 6rem" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="floating-icon" title="Toggle Info" (click)="toggleFloatingData()" *ngIf="showFloatingIcon">
  !
</div>
<div class="floating-data" *ngIf="showFloatingData">
  <table>
    <thead>
      <tr>
        <th>Position</th>
        <th>Colors</th>
        <th>Service</th>
        <th>Art</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of designData">
        <td>{{data.position}}</td>
        <td>{{data.colors}}</td>
        <td>{{data.service}}</td>
        <td class="img">
          <div [style.background-image]="'url('+data.image+')'"></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="embed-responsive embed-responsive-16by9 grid-container" style="user-select: none;">
  <div  *ngIf="isEndUser" >
    <button mat-button *ngIf="firstcollapsed" (click)="openNav();" class="button">
      <mat-icon>arrow_drop_down</mat-icon>
      Products
    </button>
    <button mat-button *ngIf="!opendesignGallery" (click)="openDesignGallery();closeProducts();" class="design-gallery-button">
      Design Gallery
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <div id="mySidenav" class="sidenav" [hidden]="firstcollapsed">
      <div *ngIf="!firstcollapsed" class="container" style="margin-top: -64px;">
        <h4 style="text-align: center; font-size: 20px; margin-top: 7px;color: #0f517f;">{{comparableProducts}}</h4>
        <h6 style="text-align: center; font-size: 11px; margin-top: -10px;  padding: 12px;color: #0f517f;">{{click}}</h6>
        <div class="containers">
          <div class="image-list">
            <div (click)="selectedColor=item?.colorList[0];goToDesigner(item);closeNav();" class="image-item"
              *ngFor="let item of product?.alternateProducts; let i = index">
              <img style="height: 70%; width: 60%; cursor: pointer;" src="{{ item?.image[0] || noImage }}" *ngIf="item?.image" />
              <h6 class="prod-name" style="font-size: 12px;">{{ item?.displayName || item?.name }}</h6>
            </div>
          </div>
          <div *ngIf="!product?.alternateProducts.length" class="w-100 text-center mt-4">
            <h1 style="text-align: center; font-size: 15px; margin-top: 7px;color: #0f517f;">No products</h1>
          </div>
        </div> 
      </div>
      <button [hidden]="productView" mat-button class="button2" (click)="closeNav();">
        Collapse Menu
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <button [hidden]="productView" mat-button class="button3" (click)="openProducts();closeDesignGallery()">
        For More Products
        <mat-icon style="transform: rotate(180deg);">arrow_drop_down</mat-icon>
      </button>
    </div>
    <div id="mySidenav2" [ngClass]="{'sidenav2': productView}" [hidden]="!productView">
      <div [ngStyle]="{ display: isHidden ? 'none' : 'block' }" id="myDiv" class="container">
        <h1
          style="margin-bottom: 20px;  text-align: center; font-size: 20px; margin-top: -52px; color: #0f517f; text-align: center;">
          Product Categories</h1>
        <div class="container" style="height: 70px; " [ngClass]="{ 'scrollcategory': showMore }">
          <ul class="category-list">
            <li *ngFor="let item of categoryList.slice(0, showMore ? categoryList.length : 15)"
              (click)="selectCategory(item)" style="text-align: center; cursor: pointer;"> {{ item?.name }}</li>
          </ul>
        </div>

        <div class="container" style="position: relative;right: -43%;">
          <button class="show-more"
            (click)="showMore = !showMore">{{ showMore ? 'Show Less' : 'Show More' }}</button>
        </div>
        <div class="container">
            <div class="search"><img style="height: 34px;  padding: 0.5rem;" src="../../../../../assets/images/search.png">
          <input type="text" placeholder="Search all products" (keypress)="onSearchKeypress($event)"
            [(ngModel)]="searchText" style=" width: 80%;border: none; outline: none;">
        </div>
        </div>
      
        <div class="container">
          <div class="w-100 text-center" *ngIf="loading">
            <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="prod-card-container">
          <div class="prod-card" *ngFor="let item of productList">

            <div class="img-container">
              <img (click)="selectedColor=item?.colorList[0];goToDesigner(item);closeProducts();closeNav();"
                src="{{ item?.image[0] || noImage }}" />
            </div>
            <div class="detail-container">
              <div class="available-colors custom-scroll">
                <div class="color" *ngFor="let color of item?.colorList" [ngStyle]="{
          'background-color': color.hexValue,
          'background-image': 'url(' + color.swatch + ')'
        }" (click)="selectedColor=color;goToDesigner(item);closeProducts();closeNav();"></div>
              </div>
              <p class="prod-name">{{ item?.displayName || item?.name }}</p>
              <p class="style-name">
                {{ item?.vendorName ? item?.vendorName + " | " : "" }}
                {{ item?.styleName }}
              </p>
              <p class="base-price">${{ item?.price }}</p>
            </div>
          </div>
          <div class="container" style="position: relative; right: -159%;  color: #0f517f;">
            <div *ngIf="!productList.length && !loading" class="w-100 text-center mt-4">
              <h4>No products in this category</h4>
            </div>
          </div>
        </div>

        <div class="container">
          <mat-paginator [length]="paginatorLength" [pageSize]="paginatorPageSize"
            [pageSizeOptions]="paginatorPageSizeOptions" (page)="pageEvent($event)">
          </mat-paginator>
        </div> 
      </div>

      <button *ngIf="productView" mat-button class="button4" (click)="closeProducts()">
        Collapse Menu
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div> 
    <div id="mySidenav3" [ngClass]="{'sidenav3': opendesignGallery}" [hidden]="!opendesignGallery">

      <div class="container" [ngStyle]="{ display: isHiddenDesignGalley ? 'none' : 'block' }">
        <div class="container">
          <button *ngIf="backButton" (click)="backButton=false" type="button" class="back">
            <img style="background: white;  width: 40%;  border: none;  margin-bottom: 10px;"
              src="../../../assets/icon/back.png" alt="">
            <span style="margin-left: -7px;">Back</span>
          </button>
        </div>
        <div class="search">
          <img style="  position: absolute;  left: 34px;  width: 25px; height: 29px;  border-top: outset;"
            src="../../../../../assets/images/search.png">
          <input type="text" placeholder="Search Design Templates" (keypress)="onTemplateSearchKeypress($event)"
            [(ngModel)]="searchTemplateText"
            style="    width: 86%; outline: none; border: none;  border-radius: inherit;   font-size: 13px; color: #333;   background: white; vertical-align: top;  float: right;  margin-top: 2%;">
        </div>
        <div class="container mt-2">
          <div class="row" *ngIf="!backButton">
            <div class="col-md-12 right-side-box" >
              <div class="grid-container2">
                <span
                  *ngFor="let item of templateCategoryList?.slice(0, showMore ? templateCategoryList?.length : 24)"
                  (click)="templateList=null;backButton=true;selectTemplateCategory(item)"
                  class="grid-item2"
                >
                  {{ item?.name }}
                </span>
              </div>
            </div>
          </div>
          
          <!-- <div *ngIf="!backButton"
            style="display: grid; grid-template-columns: repeat(2, 1fr); grid-row-gap: -2px; grid-column-gap: 0px; overflow: auto; width: 102%; align-content: center; justify-content: space-evenly; align-items: baseline;  justify-items: center;">
            <span *ngFor="let item of templateCategoryList?.slice(0, showMore ? templateCategoryList?.length : 24)"
              (click)="templateList=null;backButton=true;selectTemplateCategory(item)"
              style="color: #26628b;cursor: pointer;  width: 34px; padding: 0; margin: 0; font-size: 16px; line-height: 19px; font-weight: 500; list-style: none;text-align: center; ">
              {{ item?.name }}</span>
          </div> -->
        </div>
        <div class="container" style="text-align: center;">
          <button *ngIf="!backButton&&templateCategoryList?.length>24"
            style="padding: 7px 10px;  text-align: center; border-radius: 3px;  font-size: 12px;  display: inline-table; line-height: 12px;background-color: lightblue; color: white; border: none; padding: 10px; margin-top: 10px; cursor: pointer; margin-bottom: 20px;"
            class="ng-tns-c112-0" (click)="showMore = !showMore">{{ showMore ? 'Show Less' : 'Show More' }}</button>
        </div>

        <div *ngIf="backButton" class="container">
          <h4 style="text-align: center; font-size: 20px; margin-top: 7px;color: #214f8f;">{{selectedTemplate.name}}
          </h4>
        </div>
       
        <div *ngIf="backButton" style="overflow-y: auto;  width: 100%; height: 72%;">
          <div class="w-100 text-center" *ngIf="loading">
            <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ul *ngIf="templateList.length" class="image-list"
            style="display: grid;  grid-template-columns: repeat(2, 1fr); grid-row-gap: 0px; grid-column-gap: 0px;  width: 80%; align-content: center;  justify-content: center;  align-items: baseline;   justify-items: center; ">
            <li class="prod-card" *ngFor="let item of templateList">
              <div *ngIf="item.is_disable!=1"
                style="width: 100px; height: 103px; margin: 8px; float: 3px; text-align: center; border: 1px solid #2c6288;"
                (click)="closeNav();templateClicked(item);closeDesignGallery()">
                <img style="width: 95px;   height: 80px; cursor: pointer;" src="{{ item?.capture_images.without_product_file[0].thumb
            || noImage }}" />
                <h6 style=" text-align: center; font-size: 60%; ">{{item.name}}</h6>
              </div>
            </li>
          </ul>
          <div *ngIf="!templateList.length && !loading" class="w-100 text-center mt-4">
            <h6 style="color: #214f8f;">No templates in this category</h6>
          </div>
         
          
        </div>

        <div *ngIf="backButton" class="container">
          <mat-paginator
            style="display: flex; align-items: center;  justify-content: flex-end;  padding: 0px 8px;  flex-wrap: wrap-reverse; width: 85%;"
            [length]="paginatorLengthTemplate" [pageSize]="paginatorPageSizeTemplate"
            [pageSizeOptions]="paginatorPageSizeOptions" (page)="pageEventTemplate($event)">
          </mat-paginator>
        </div>
      </div> 
      <button mat-button class="button5" (click)="closeDesignGallery()">
        Collapse Menu
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
  </div> 
  <iframe class="embed-responsive-item ifr"   id="designeriframe" allowfullscreen> 
  </iframe>
</div>



