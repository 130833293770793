<div class="row">
  <div class="col-md-1 side-bar">
    <div class="img-tab-wrapper">
      <div class="sideTab" (click)="redirect('/productspreadsheet','Store Product Listing')" matTooltip="Store Product Listing" matTooltipPosition="above">
        <img src="../../../../assets/images/shirt.png" class="sideBarImgTab" />
      </div>
      <div class="sideTab" (click)="redirect('/storeproductcategories','Product Categories')" matTooltip="Product Categories" matTooltipPosition="above">
        <img src="../../../../assets/images/category-icon.png" class="sideBarImgTab" />
      </div>
      <div class="sideTab" (click)="redirect('/activeart','Active Art')" matTooltip="Active Art" matTooltipPosition="above">
        <img src="../../../../assets/images/canvas.png" class="sideBarImgTab" />
      </div>
      <!-- <div class="sideTab" (click)="redirect('/reviewproduct','Review Products')" matTooltip="Review Products" matTooltipPosition="above">
        <img src="../../../../assets/images/review-icon.png" class="sideBarImgTab" />
      </div> -->
      <div class="sideTab" (click)="redirect('/storesettings/storename','Settings')" matTooltip="Settings" matTooltipPosition="above">
        <img src="../../../../assets/images/checklist.png" class="sideBarImgTab" />
      </div>
      <!-- <div class="sideTab" (click)="redirect('/couponsanddiscounts','Coupons and Discounts')" matTooltip="Coupons and Discounts" matTooltipPosition="above">
        <img src="../../../../assets/images/tickets.png" class="sideBarImgTab" />
      </div> -->
      <div class="sideTab" (click)="redirect('/styleeditor','Store Style Editor')" matTooltip="Store Style Editor" matTooltipPosition="above">
        <img src="../../../../assets/images/Group 2835.png" class="sideBarImgTab" />
      </div>
      <div class="sideTab" (click)="redirect('/storelayout','View Store')" matTooltip="View Store" matTooltipPosition="above">
        <img src="../../../../assets/images/Group 2841.png" class="sideBarImgTab" />
      </div>
      <div class="sideTab" (click)="redirect('/storesummary','Store Summary')" matTooltip="Store Summary" matTooltipPosition="above">
        <img src="../../../../assets/images/up-arro.png" class="sideBarImgTab" />
      </div>
    </div>

  </div>
  <div class="col-md-11">
    <div class="selected-tab-view">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
