import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CouponDetailsService {
  private baseUrl = `${environment.apiUrl}`;


  constructor(
    private http: HttpClient,
  ) { }

  addCoupon(data) {
    return this.http.post<any>(this.baseUrl + "v1/coupon", data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editCoupon(data) {
    return this.http
      .put(this.baseUrl + "v1/coupon", data, { observe: "response" })
      .pipe(
        map((response: any) => {
          return response.body;
        })
      );
  }


  storeDetails() {
    return this.http.get(this.baseUrl + "v1/teamstore/short/view").pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          return response;
        }
      })
    );
  }

  validateCoupon(key) {
    return this.http.get(this.baseUrl + "v1/coupon/" + key).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          return response;
        }
      })
    );
  }

  viewCouponList(params) {
    let url = this.baseUrl + "v1/coupon?"
    url += "per_page=" + (params.per_page || '')
    url += "&page=" + (params.page || '')
    return this.http
      .get<any>(url,
        { observe: "response" })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  applyCoupon(data) {
    return this.http.post<any>(this.baseUrl + "v1/coupon/apply", data).pipe(
      map((response) => {
        return response;
      })
    );
  }
  // myCouponList(params){
  //   let url = this.baseUrl + "api/users/coupons"
  //   url += "per_page=" + (params.per_page || '')
  //   url += "&page=" + (params.page || '')
  //   return this.http
  //     .get<any>(url,
  //       { observe: "response" })
  //     .pipe(
  //       map((response) => {
  //         return response;
  //       })
  //     );
  // }

  myCoupons(params) {
    return this.http.get(
      this.baseUrl + "users/coupons?" +
      '&page=' + params.pagination.page +
      '&per_page=' + params.pagination.per_page,
      { observe: "response" }
    ).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          return response;
        }
      })
    );
  }
}
