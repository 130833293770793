<div class="grid-container" [ngStyle]="{'background-color': layoutData.layout.backgroundColor,'color': layoutData.layout.fontColor}">
    <div class="banner" *ngIf="layoutData.disclaimer" [ngStyle]="{'background-color': layoutData.banner?.backgroundColor,'color': layoutData.banner?.fontColor}">
        {{layoutData.disclaimer}}
    </div>
    <div class="header"
        [ngStyle]="{'background-color': layoutData.header.backgroundColor,'color': layoutData.header.fontColor}">
        <!-- <div class="title"></div> -->
        <div class="logo">
            <img src={{layoutData.header.logoUrl}} *ngIf="layoutData.header.logoUrl != ''"
            [ngStyle]="{'height': layoutData.header.logoHeight , 'max-width': layoutData?.header?.logoWidth}"  >
        </div>
    </div>
    <div class="timer">
        <app-count-down-timer [layoutData]="layoutData" [teamStoreData]="teamStoreData"></app-count-down-timer>
    </div>
    <div class="content">
        <div class="category" *ngFor="let category of productsByCategory;let j=index">
            <div class="category-name">
                {{category.categoryName}}
            </div>
            <div class="category-products">
                <div class="prod-card" *ngFor="let product of category.products; let i = index">
                    <div class="prod-image"
                        (click)="clickEvent('productSelected',product.id,product.colorId,product.mapCode)"
                        [ngStyle]="{'background-image':'url('+(selectedImage[j][i] || product.images[0] || noImagePlaceholder)+')'}">
                    </div>
                    <div class="prod-thumbnail">
                        <div class="thumbnail" *ngFor="let img of product.images"
                            [ngStyle]="{'background-image':'url('+img+')'}" (click)="selectedImage[j][i] = img;">
                        </div>
                    </div>
                    <div class="prod-detail"
                        (click)="clickEvent('productSelected',product.id,product.colorId,product.mapCode)">
                        <p class="prod-name"> {{product.name}} </p>
                        <p class="prod-price"> ${{product.price}} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer"
        [ngStyle]="{'background-color': layoutData.footer.backgroundColor,'color': layoutData.footer.fontColor}">
        <div class="contents">
            <div class="contact-us">
                <label>CONTACT US</label>
                <div class="address">
                    <mat-icon aria-hidden="true" data-mat-icon-type="font">place</mat-icon>
                    <p>3700 LACON RD SUITE A.<br> HILLIARD, OH 43026-1207</p>
                </div>
                <div class="phone">
                    <mat-icon aria-hidden="true" data-mat-icon-type="font">phone</mat-icon>
                    <p>800-439-8614</p>
                </div>
                <div class="email">
                    <mat-icon aria-hidden="true" data-mat-icon-type="font">mail</mat-icon>
                    <p>info@areswear.com</p>
                </div>
            </div>
        </div>
    </div>
</div>