import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private commonService: CommonService,
        ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                //this.authenticationService.logout();
                // location.reload();
                const user = JSON.parse(localStorage.getItem('user'))
                if(user && user.roles.id != 6){
                    this.commonService.openErrorSnackBar("Unauthorized","")
                    // this.router.navigateByUrl("/")
                    window.parent.postMessage("invalid user", "*");
                }
            }
            
            // const error = err.error.message || err.statusText;
            return throwError(err);
        }))
    }
}
