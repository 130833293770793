<form [formGroup]="notificationGroup">
  <div class="row padding10">
    <div class="col-md-8">
      <p style="font-size: x-large;">Store Notifications</p>
      <mat-hint>Does this store have employees, business partners, sales reps, or other non-customers who need to stay in the loop? Add them here to automatically notify them of orders and shipping updates via email.</mat-hint>
    </div>
    <div class="col-md-4" style="text-align: right;">
      <button class="btn btn-primary btnClass" (click)="saveEmailNotificationSettings()">SAVE</button>
    </div>
  </div>
  <br>
  <div class="row padding10" formArrayName="notificationArray" *ngFor="let option of notificationGroup.get('notificationArray')['controls'];  let i = index">
    <br>
    <div class="col-md-12">
      <mat-divider></mat-divider>
    </div>
    <div class="col-md-12">
      <mat-form-field [formGroupName]="i" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;">
        <mat-label>Email</mat-label>
        <input type="email" class="mdl-textfield__input" matInput formControlName="email" id='{{i}}'>
        <!-- <mat-error *ngIf="notificationGroup.get('notificationArray').errors?.required">
          Email Id is required
        </mat-error> -->
        <!-- <mat-error *ngIf="notificationGroup.get('email').errors?.pattern">
          Please enter a valid email address
        </mat-error> -->
        <mat-icon mat-button matSuffix (click)="deleteRow(i)" style="cursor: pointer;">close</mat-icon>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <div [formGroupName]="i">
        <mat-checkbox class="example-margin" formControlName="instantOrderNotification">
          <mat-hint>Instant Order Notifications</mat-hint>
        </mat-checkbox>
      </div>
      <div [formGroupName]="i">
        <mat-checkbox class="example-margin" formControlName="dailyOrderSummary">
          <mat-hint>Daily Order Summary</mat-hint>
        </mat-checkbox>
      </div>
      <div [formGroupName]="i">
        <mat-checkbox class="example-margin" formControlName="shippingNotification">
          <mat-hint>Shipping Notifications</mat-hint>
        </mat-checkbox>
      </div>
      <div [formGroupName]="i">
        <mat-checkbox class="example-margin" formControlName="contactUsNotification">
          <mat-hint>Contact Us Notifications</mat-hint>
        </mat-checkbox>
      </div>
      <div [formGroupName]="i">
        <mat-checkbox class="example-margin" formControlName="savedDesignNotification">
          <mat-hint>Saved Design Notifications</mat-hint>
        </mat-checkbox>
      </div>
    </div>
  </div>
  <br>
  <div class="col-md-12 padding10">
    <button class="btn btn-primary btnClass" (click)="addRow()">ADD EMAIL ADDRESS</button> &nbsp;
  </div>
</form>
