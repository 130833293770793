<br><br>
<div class="row  justify-content-center">
  <mat-card class="col-md-4" style="text-align: center;">

    <img width="60%" height="25%" src="../../../../assets/images/Logo.png">
    <br><br>
    <h4>Activate your account</h4>
    <br>
    <h6>Complete your account details</h6>
    <form [formGroup]="userVerificationForm" style="text-align: center;">
      <mat-form-field style="width: 100%;">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email" readonly>
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password">
        <mat-error *ngIf="userVerificationForm.get('password').errors?.required">
          Password is required
        </mat-error>
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <mat-label>Confirm Password</mat-label>
        <input type="password" matInput formControlName="confirmPassword">
      </mat-form-field>
      <br>
      <div class="form-group">
        <button class="btn btn-primary" (click)="verifyUserAccount()">
          <!-- <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> -->
          Activate
        </button>
      </div>
      <br><br>
    </form>
  </mat-card>
</div>
