<div class="metadata-container row">
  <div class="col-12 w-100">
    <span style="font-size: x-large">Store Metadata</span>
    <button
      class="btn btn-primary save-btn m-3 float-right"
      (click)="saveMetadata()"
    >
      SAVE
    </button>
    <button
      class="btn btn-secondary m-3 float-right"
      *ngIf="data && data.storeId"
      (click)="closeDialog()"
    >
      CANCEL
    </button>
  </div>
  <div class="col-4">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Primary Color</mat-label>
      <mat-select [(ngModel)]="primaryColor">
        <mat-option *ngFor="let color of primaryColorList" [value]="color.id">
          {{ color.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Secondary Color</mat-label>
      <mat-select [(ngModel)]="secondaryColor">
        <mat-option *ngFor="let color of secondaryColorList" [value]="color.id">
          {{ color.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Tertiary Color</mat-label>
      <mat-select [(ngModel)]="tertiaryColor">
        <mat-option *ngFor="let color of tertiaryColorList" [value]="color.id">
          {{ color.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-6 mb-4">
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Sport</mat-label>
      <input
        type="text"
        matInput
        [formControl]="sportControl"
        [matAutocomplete]="autoSport"
      />
      <mat-autocomplete
        #autoSport="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="addMetadata($event, 'sport')"
      >
        <mat-option
          *ngFor="let sport of filteredSports | async"
          [value]="sport"
        >
          {{ sport.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="selected-items">
      <span *ngFor="let sport of selectedSports">
        <mat-icon (click)="removeMetadata(sport, 'sport')">close</mat-icon>
        {{ sport.name }}
      </span>
    </div>
  </div>
  <div class="col-6 mb-4">
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Brand</mat-label>
      <input
        type="text"
        matInput
        [formControl]="brandControl"
        [matAutocomplete]="autoBrand"
      />
      <mat-autocomplete
        #autoBrand="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="addMetadata($event, 'brand')"
      >
        <mat-option
          *ngFor="let brand of filteredBrands | async"
          [value]="brand"
        >
          {{ brand.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="selected-items">
      <span *ngFor="let brand of selectedBrands">
        <mat-icon (click)="removeMetadata(brand, 'brand')">close</mat-icon>
        {{ brand.name }}
      </span>
    </div>
  </div>
  <div class="col-6 mb-4">
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Keywords</mat-label>
      <textarea
        matInput
        placeholder="Enter comma separated keywords (Nike, Shirt, Best Seller, ...)"
        rows="1"
        [(ngModel)]="teamStoreKeywords"
      >
        {{ teamStoreKeywords }}
      </textarea>
    </mat-form-field>
  </div>
  <div class="col-6 mb-4">
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Best Fundraiser</mat-label>
      <mat-select [(ngModel)]="isBestFundRaiser">
        <mat-option [value]="true"> Yes </mat-option>
        <mat-option [value]="false"> No </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-12 text-right ">
    <mat-checkbox
          labelPosition="before"
          class="custom-checkbox "
          [(ngModel)]="availableToCopy"
        >
          <mat-hint>Store is visible on areswear.com</mat-hint>
        </mat-checkbox>
  </div>
</div>