import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-coupons-discounts',
  templateUrl: './coupons-discounts.component.html',
  styleUrls: ['./coupons-discounts.component.scss']
})
export class CouponsDiscountsComponent implements OnInit {

  isCouponListHidden: boolean;
  isCouponCreateHidden: boolean;
  panelOpenState : boolean;

  generalGroup: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.changeCouponVisibility(true);
    this.panelOpenState= false;

    this.generalGroup= this.formBuilder.group({
      effectiveDate: new UntypedFormControl(''),
      expirationDate: new UntypedFormControl('')
    });
  }

  changeCouponVisibility(value: boolean){
    this.isCouponCreateHidden= value;
    this.isCouponListHidden= !value;
  }
}
