<div class="filter">
  <mat-accordion>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="font-weight-bold"> Filter </mat-panel-title>
        <mat-panel-description>
          <div class="w-100 text-right font-weight-bold">
            <mat-icon>filter_list</mat-icon>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="filter-fields row">
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Store Id</mat-label>
            <input matInput type="text" [(ngModel)]="filterParams.storeId" (change)="getStoreRequestList()" />
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Store Name</mat-label>
            <input matInput type="text" [(ngModel)]="filterParams.name" (change)="getStoreRequestList()" />
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Store Owner</mat-label>
            <input matInput type="text" [(ngModel)]="filterParams.contactName" (change)="getStoreRequestList()" />
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Store Manager</mat-label>
            <mat-select [(ngModel)]="filterParams.accountManagerId" (selectionChange)="getStoreRequestList()">
              <mat-option [value]="0">All</mat-option>
              <mat-option [value]="mgr.id" *ngFor="let mgr of managerList">{{
                mgr.name
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Store Builder</mat-label>
            <mat-select [(ngModel)]="filterParams.builderId" (selectionChange)="getStoreRequestList()">
              <mat-option [value]="0">All</mat-option>
              <mat-option [value]="builder.id" *ngFor="let builder of builderList">{{ builder.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Store Status</mat-label>
            <mat-select [(ngModel)]="filterParams.type" (selectionChange)="getStoreRequestList()" multiple>
              <mat-option value="1">Pending</mat-option>
              <mat-option value="2">Requested</mat-option>
              <mat-option value="4">Assigned to Builder</mat-option>
              <mat-option value="15">Build in Progress</mat-option>
              <mat-option value="5">Proofing Store</mat-option>
              <mat-option value="6">Submitted for Approval</mat-option>
              <mat-option value="7">Making Adjustments</mat-option>
              <mat-option value="8">Waiting for Finalizing</mat-option>
              <mat-option value="9">Store Published</mat-option>
              <mat-option value="10">Store Opened</mat-option>
              <mat-option value="11">Store Closed</mat-option>
              <mat-option value="19">Customer Rejected</mat-option>
              <mat-option value="20">Customer Approved</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Close Date From</mat-label>
            <input matInput [matDatepicker]="fromPicker1" [(ngModel)]="dateFilters.closeDateFrom"
              (dateChange)="getStoreRequestList()" />
            <mat-datepicker-toggle matSuffix [for]="fromPicker1"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Close Date To</mat-label>
            <input matInput [matDatepicker]="toPicker1" [(ngModel)]="dateFilters.closeDateTo"
              (dateChange)="getStoreRequestList()" />
            <mat-datepicker-toggle matSuffix [for]="toPicker1"></mat-datepicker-toggle>
            <mat-datepicker #toPicker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Open Date From</mat-label>
            <input matInput [matDatepicker]="fromPicker2" [(ngModel)]="dateFilters.openDateFrom"
              (dateChange)="getStoreRequestList()" />
            <mat-datepicker-toggle matSuffix [for]="fromPicker2"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Open Date To</mat-label>
            <input matInput [matDatepicker]="toPicker2" [(ngModel)]="dateFilters.openDateTo"
              (dateChange)="getStoreRequestList()" />
            <mat-datepicker-toggle matSuffix [for]="toPicker2"></mat-datepicker-toggle>
            <mat-datepicker #toPicker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Requested Date From</mat-label>
            <input matInput [matDatepicker]="fromPicker3" [(ngModel)]="dateFilters.requestedDateFrom"
              (dateChange)="getStoreRequestList()" />
            <mat-datepicker-toggle matSuffix [for]="fromPicker3"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker3></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Requested Date To</mat-label>
            <input matInput [matDatepicker]="toPicker3" [(ngModel)]="dateFilters.requestedDateTo"
              (dateChange)="getStoreRequestList()" />
            <mat-datepicker-toggle matSuffix [for]="toPicker3"></mat-datepicker-toggle>
            <mat-datepicker #toPicker3></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Customer Approval Status</mat-label>
            <mat-select [(ngModel)]="filterParams.isUserVerified" (selectionChange)="getStoreRequestList()">
              <mat-option [value]="0">All</mat-option>
              <mat-option [value]="2">Approved</mat-option>
              <mat-option [value]="1">Rejected</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Templates</mat-label>
            <mat-select [(ngModel)]="filterParams.inTemplate" (selectionChange)="getStoreRequestList()">
              <mat-option [value]="0"> Show All </mat-option>
              <mat-option [value]="1"> Show Only Templates </mat-option>
              <mat-option [value]="2"> Hide Templates </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 col-xl-2">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>State</mat-label>
            <mat-select [(ngModel)]="filterParams.stateCode" (selectionChange)="getStoreRequestList()">
              <mat-option [value]="0"> Show All </mat-option>
              <mat-option *ngFor="let item of stateList" [value]="item.id"> {{item.name}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="w-100 text-right">
        <button class="btn btn-sm btn-outline-primary" (click)="getStoreRequestList()">
          Filter
        </button>
        <button class="btn btn-sm btn-outline-primary ml-3" (click)="clearFilters()">
          Clear Filter
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class="table-wrapper">
  <table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="dataSource" multiTemplateDataRows
    class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Store Id</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
        <span class="template-icon" *ngIf="element.availableToCopy">T</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="storeName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Store Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="contactName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Store Owner</th>
      <td mat-cell *matCellDef="let element">{{ element.contactName }}</td>
    </ng-container>
    <ng-container matColumnDef="manager">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Manager</th>
      <td mat-cell *matCellDef="let element">{{ element.createdBy?.name }}</td>
    </ng-container>
    <ng-container matColumnDef="builder">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Builder</th>
      <td mat-cell *matCellDef="let element">{{ element.assignedToName }}</td>
    </ng-container>
    <ng-container matColumnDef="verifiedStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Customer Verified
      </th>
      <td mat-cell *matCellDef="let element">
        <label *ngIf="element.isUserVerified == 0">Pending</label>
        <label *ngIf="element.isUserVerified == 1">Rejected</label>
        <label *ngIf="element.isUserVerified == 2">Approved</label>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Store Status</th>
      <td mat-cell *matCellDef="let element">
        <label *ngIf="element.stage == 1">Pending</label>
        <label *ngIf="element.stage == 2">Requested</label>
        <label *ngIf="
            element.stage == 3 ||
            element.stage == 15 ||
            element.stage == 5 ||
            element.stage == 18
          ">
          Build In Progress</label>
        <label *ngIf="element.stage == 4">Assigned To Builder</label>
        <label *ngIf="element.stage == 6">Pending Approval</label>
        <label *ngIf="element.stage == 7">Account Manager Rejected</label>
        <label *ngIf="element.stage == 9">Published</label>
        <label *ngIf="element.stage == 19">Customer Rejected</label>
        <label *ngIf="element.stage == 20">Customer Approved</label>
        <label *ngIf="element.stage == 10">Live</label>
        <label *ngIf="element.stage == 11">Closed</label>
      </td>
    </ng-container>
    <ng-container matColumnDef="requestedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Requested Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdDate | date : "MM/dd/yyyy hh:mm a" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="publishedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span *ngIf="stage == '9'">Published Date </span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="stage == '9'">
          {{ element.publishedDate | date : "MM/dd/yyyy hh:mm a" }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="openDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Open Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.openDate | date : "MM/dd/yyyy hh:mm a" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="closeDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Close Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.closeDate | date : "MM/dd/yyyy hh:mm a" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="viewStore">
      <th mat-header-cell *matHeaderCellDef>View Store</th>
      <td mat-cell *matCellDef="let element" (click)="viewStoreBuild(element)">
        <span *ngIf="[20, 19, 11, 10, 9, 8, 7, 6].includes(element.stage)">View</span>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail" *ngIf="hasAccess(7)">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          ">
          <button *ngIf="element.stage == 6" mat-raised-button color="primary" (click)="verifyStoreBuild(element.id)">
            Verify Store Build
          </button>
          <button *ngIf="[6, 11, 10].includes(element.stage)" mat-raised-button color="primary"
            (click)="verifyArt(element)">
            View Art
          </button>
          <button *ngIf="element.stage == 6" mat-raised-button color="primary" (click)="updateStoreStage(element, 9)">
            Publish Store
          </button>
          <button *ngIf="element.stage == 20" mat-raised-button color="primary" (click)="updateStoreStage(element, 10)">
            Open Store
          </button>
          <button *ngIf="element.stage == 10" mat-raised-button color="primary" (click)="closeStore(element)">
            Close Store
          </button>
          <button *ngIf="element.stage == 11" mat-raised-button color="primary" (click)="republishStore(element)">
            Re-Publish Store
          </button>

          <button *ngIf="element.stage == 7 || element.stage == 19" mat-raised-button color="primary"
            (click)="openInfoModal(element)">
            Rejection Notes
          </button>
          <button mat-raised-button color="primary" (click)="openStoreClosureModal(element)"
            *ngIf="[10, 9, 19, 20].includes(element.stage)">
            Edit Store Closure Date
          </button>
          <button mat-raised-button color="primary" *ngIf="element.stage == 9 && element.isUserVerified == 0"
            (click)="openRejectModal(element)">
            Reject for Customer
          </button>
          <button *ngIf="
              [10, 11, 9].includes(element.stage) && element.availableToCopy
            " mat-raised-button color="primary" (click)="copyLayout(element.id)">
            Copy Store Layout
          </button>
          <button *ngIf="[10].includes(element.stage)" mat-raised-button color="primary"
            (click)="copyStoreURL(element.liveStoreUrl)">
            Copy Live Store URL
          </button>
          <button mat-raised-button color="primary" (click)="editMetadata(element)">
            Edit Metadata
          </button>

          <!-- *ngIf="[6, 11, 10].includes(element.stage)" -->
          <button mat-raised-button color="primary" (click)="openPdfModal(element)" *ngIf="element.stage == 10">
            Generate Pdf
          </button>

        </div>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <ng-container *ngIf="hasAccess(7)">
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </ng-container>
  </table>

  <mat-paginator (page)="onPage($event)" [length]="filterParams.totalLength" [pageSize]="filterParams.per_page"
    [pageSizeOptions]="filterParams.pageSizeOptions" aria-label="Select page">
  </mat-paginator>
</div>

<div class="modal fade" id="storeClosureModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="storeForm" (ngSubmit)="updateStoreClosure()">
          <!-- <mat-form-field style="width: 100%;">
            <mat-label>Close Date</mat-label>
            <input matInput [matDatepicker]="closeDate" formControlName="closeDate" (click)="closeDate.open()" />
            <mat-datepicker-toggle matSuffix [for]="closeDate"></mat-datepicker-toggle>
            <mat-datepicker #closeDate></mat-datepicker>
          </mat-form-field> -->
          <mat-form-field style="width: 100%">
            <mat-label>Close Date</mat-label>
            <input matInput [ngxMatDatetimePicker]="closeDate" formControlName="closeDate" (click)="closeDate.open()"
              [min]="storeForm.value.minDate" readonly (dateInput)="invalidClosingDay()" />
            <mat-error *ngIf="storeForm.get('closeDate').errors?.required">
              Close Date is required
            </mat-error>
            <mat-error *ngIf="storeForm.get('closeDate').errors?.matDatepickerMin">
              Close Date is not valid
            </mat-error>
            <mat-error *ngIf="storeForm.get('closeDate').errors?.invalidDay">
              Close Date cannot be Friday or Saturday
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="closeDate"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #closeDate [enableMeridian]="true"></ngx-mat-datetime-picker>
          </mat-form-field>
          <br /><br />
          <!-- [disabled]="loading" -->
          <button type="submit" class="btn btn-primary commonBtnStyle">
            <!-- <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span> -->
            update
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="rejectionInfoModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Rejection Reason</h5>
        <button type="button" class="close" (click)="closeInfoModal()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="product-rejection-notes" *ngIf="
            storeRejectionNotes?.products &&
            storeRejectionNotes?.products.length
          ">
          <table class="table">
            <thead>
              <tr>
                <th>SL.NO</th>
                <th>Product</th>
                <th>Color</th>
                <th>Rejection Codes</th>
                <th>Rejection Notes</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="
                  let product of storeRejectionNotes?.products;
                  let i = index
                ">
                <tr *ngIf="product.teamstoreGarmentStatus == 2">
                  <td>{{ i + 1 }}</td>
                  <td>{{ product.name }}</td>
                  <td>{{ product.color }}</td>
                  <td>
                    <p *ngFor="
                        let rej of filterRejectionCodes(product.rejectionCodes)
                      ">
                      {{ rej.code }}
                    </p>
                  </td>
                  <td>{{ product.rejectionText }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="store-rejection-notes mt-3">
          <label class="font-weight-bold mb-3 w-100">Store Rejection Reason</label>
          <p *ngFor="
              let rej of filterRejectionCodes(
                storeRejectionNotes?.teamStore?.rejectionCodes
              )
            ">
            {{ rej.code }}
          </p>
          <label class="font-weight-bold mb-3 w-100">Comments</label>
          <p>{{ storeRejectionNotes?.teamStore?.rejectionText }}</p>
          <div class="reference-images">
            <label class="font-weight-bold mb-3 w-100">Reference Images</label>
            <a download="{{ url.split('.')[-2] }}.{{ url.split('.')[-1] }}" href="{{ url }}" title="Click to download"
              *ngFor="let url of storeRejectionNotes?.teamStore?.referenceFiles">
              <div [ngStyle]="{ 'background-image': 'url(' + url + ')' }" class="reference-files"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="rejectStoreModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Rejection Reason</h5>
        <button type="button" class="close" (click)="closeRejectModal()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="customerRejectionForm">
          <mat-form-field style="width: 100%">
            <mat-label>Reason</mat-label>
            <mat-select formControlName="rejectionCodeArray" multiple>
              <mat-option *ngFor="let reason of rejectionCodes" [value]="reason.id">{{ reason.code }}</mat-option>
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field style="width: 100%">
            <mat-label>Comment</mat-label>
            <textarea matInput formControlName="rejectionReason"></textarea>
          </mat-form-field>
          <button type="button" class="btn btn-primary" (click)="rejectionForCustomer()">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>