import { Component, DebugEventListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { StoreService } from 'src/app/core/services/store.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  cartItems: any;
  cartGridItems: any[] = [];
  totalAmount: any = 0;
  totalNumberOfProducts: number = 0;
  teamStoreId: any;
  purchaseType: any;
  noImagePlaceholder = "../../../../assets/images/default-image.jpg"
  hidePage = false
  private wpUrl = environment.wpUrl;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    window.parent.postMessage("update title-User Cart", "*")
    this.teamStoreId = parseInt(localStorage.getItem("teamStoreId"));
    this.purchaseType = parseInt(localStorage.getItem("purchaseType"));
    this.getCartList();
    this.commonService.postScrollMessageToParent()
  }

  getCartList() {
    this.commonService.toggleLoading(true)  
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    if (userId && token) {
      this.storeService.getCartList().subscribe(response => {
        this.cartItems = response.data;
        this.cartItems.cartItemsInfo = this.cartItems.cartItemsInfo.map(v => Object.assign(v, { selected: true }))
        this.totalAmount = this.calculateTotalAmount(this.cartItems.cartItemsInfo);
        this.commonService.postHeightToParent("medium")
        window.top.postMessage("cart count-" + this.cartItems.cartTotal, "*");
        this.commonService.toggleLoading(false)  
      },
        error => {
          this.cartItems = {};
          this.cartItems.cartItemsInfo = [];
          this.totalAmount = 0;
          this.commonService.postHeightToParent("medium")
          if (error.status == 401) {
            window.parent.postMessage("open guest login", "*");
            window.parent.postMessage("action - reload page", "*");
            this.hidePage = true
          }
          this.commonService.toggleLoading(false)    
        });
    } else {
      window.parent.postMessage("open guest login", "*");
      window.parent.postMessage("action - reload page", "*");
      this.hidePage = true
      this.commonService.toggleLoading(false)   
    }
  }

  deleteItem(product: any, index: any) {
    this.commonService.toggleLoading(true)  
    this.storeService.deleteProductFromCart(product.cartId).subscribe(response => {
      if (response.status == 1) {
        if (index != null) {
          this.cartItems.cartItemsInfo.splice(index, 1);
          // window.top.postMessage("cart count-" + this.cartItems.cartItemsInfo.length, "*");
          this.getCartList()
          //this.totalAmount = this.calculateTotalAmount(this.cartItems.cartItemsInfo);
          this.commonService.toggleLoading(false)   
        }
        this.commonService.postHeightToParent("medium")
        window.top.postMessage("cart count-" + response.data, "*");
      }

    },
      error => {
        // this.commonService.openErrorSnackBar(error.message, "");
        this.commonService.postHeightToParent("medium")
      });
  }

  calculateTotalAmount(cartItemsInfo) {
    let totalAmount = 0;
    cartItemsInfo.forEach(item => {
      totalAmount += item.totalPrice;
    });
    return totalAmount.toFixed(2);
  }

  checkout() {
    let arr = [];
    let teamStoreIds = []
    this.cartItems.cartItemsInfo.forEach(item => {
      // if (item.selected) {
      if (!teamStoreIds.includes(item.teamStoreId))
        teamStoreIds.push(item.teamStoreId)
      arr.push(item);
      // }
    });
    if (teamStoreIds.length > 1) {
      Swal.fire({
        icon: 'info',
        position: 'top',
        // title: 'Remove unwanted products',
        html: '<h4>Remove unwanted products</h4><br><h5>Multiple type of orders detected. Only one teamstore or direct purchase is allowed. Please remove unwanted products to continue.</h5>'
      })
      this.commonService.postScrollMessageToParent()
      return
    }
    let checkoutObj = {
      cartItemsInfo: arr,
      totalPrice: Number(this.calculateTotalAmount(arr)),
      taxAmount: this.cartItems.taxAmount,
      totalWeight: this.cartItems.totalWeight,
      //shippingBoxes: this.cartItems.shippingBoxes,  // this code is not usig, because showing local storage exceed issues.
      shippingBoxes: null,  
      cartCount: this.cartItems.cartTotal,
    }
    localStorage.setItem("purchaseType", this.cartItems.cartItemsInfo[0].purchaseType)
    localStorage.setItem("teamStoreId", this.cartItems.cartItemsInfo[0].teamStoreId)
    localStorage.setItem("checkoutObj", JSON.stringify(checkoutObj));
    if (this.purchaseType == 1 || this.purchaseType == 2) {
      this.router.navigateByUrl('enduser/directpurchase/checkout');
    } else {
      this.router.navigateByUrl('enduser/buyfromlivestore/checkout');
    }
  }

  addMore(product) {
    localStorage.setItem("purchaseType", product.purchaseType);
    if (product.purchaseType == 3) {
      localStorage.setItem("teamStoreId", product.teamStoreId);
      this.router.navigateByUrl('enduser/buyfromlivestore/editproductview/' + product.productId + "-" + (product.cartItemGrids[0].colorId || '0') + '/' + product.cartId + '/' + product.mapCode);
    } else {
      if (product.purchaseType == 2) {
        localStorage.setItem("productId", product.productId);
        this.router.navigateByUrl('enduser/directpurchase/editproductview/' + product.designerCartId + '/' + product.cartId);
      } else {
        this.router.navigateByUrl('enduser/directpurchase/editproductview/' + product.productId + "-" + (product.cartItemGrids[0].colorId || '0') + '/' + product.cartId);
      }

    }
  }

  goBackToProductSelection() {
    // if (this.purchaseType == 1 || this.purchaseType == 2) {
    //   // this.router.navigateByUrl('enduser/directpurchase/productlist');
    //   window.parent.postMessage("goto store", "*")
    // } else {
    //   if (this.cartItems.cartItemsInfo && this.cartItems.cartItemsInfo.length > 0) { 
    //     //if(storeOrderCount.length>1)
    //     this.router.navigateByUrl('enduser/buyfromlivestore/store/' + this.cartItems.cartItemsInfo[this.cartItems.cartItemsInfo.length - 1].teamStoreId);
    //   } else {
    //     // this.router.navigateByUrl('enduser/directpurchase/productlist');
    //     window.parent.postMessage("goto store", "*")
    //   }
    // }

    // let storeOrderCount=this.cartItems.cartItemsInfo.some(item => item.teamStoreId != null);
    // if(storeOrderCount){
    //   const firstStoreId = this.cartItems.cartItemsInfo[0].teamStoreId; 
    //   const allStoreIdsAreSame = this.cartItems.cartItemsInfo.every(item => item.teamStoreId === firstStoreId);
    //   if(allStoreIdsAreSame){
    //     this.router.navigateByUrl('enduser/buyfromlivestore/store/' + firstStoreId);
    //   }
    //   else{
    //   //window.parent.postMessage("goto store", "*")  
    //   this.router.navigateByUrl('enduser/directpurchase/productlist');
    //   }
    // }else{
    //   //window.parent.postMessage("goto store", "*")  
    //   this.router.navigateByUrl('enduser/directpurchase/productlist');
    // }

     if(this.cartItems?.cartItemsInfo[0]?.purchaseType!=3){
      let url=this.wpUrl+'Product-List/'
      window.parent.location.href = url; 
     }else{ 
      let teamStoreUrl=this.cartItems?.cartItemsInfo[0]?.teamStoreUrl ;
      window.parent.location.href = teamStoreUrl; 
     }
  }

  removeSelected() {
    let selectedCartIdArray = []
    this.cartItems.cartItemsInfo.forEach(cart => {
      if (cart.selected) {
        selectedCartIdArray.push(cart.cartId)
      }
    });
    if (selectedCartIdArray.length > 0) {
      this.storeService.deleteSelectedProductsFromCart(selectedCartIdArray).subscribe(
        res => {
          this.commonService.openSuccessSnackBar(res.message, "");
          this.getCartList()
        },
        err => {
          this.commonService.openErrorSnackBar("Unable to remove items", "");
        }
      )
    } else {
      this.commonService.openWarningSnackBar("Select products to remove", "");
    }

  }

  openPersonalizationModal(product) {
    this.cartGridItems = product.cartItemGrids;
    if (this.cartGridItems != null)
      this.togglePersonalizationModal()
    this.commonService.postScrollMessageToParent()
  }

  togglePersonalizationModal() {
    $('#personalizationModal').modal('toggle');
  }
}
