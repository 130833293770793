<div class="prod-container container">
  <div
    class="prod-card"
    *ngFor="let p of uniqueProducts; let i = index"
  >
    <ng-container *ngIf="i < 8">
      <div
        class="prod-image"
        [ngStyle]="{ 'background-image': 'url(' + p.images[0] + ')' }"
      ></div>
      <div class="prod-detail">
        <p class="prod-name">{{ p?.name }}</p>
        <p class="prod-price">${{ p?.price }}</p>
      </div>
    </ng-container>
  </div>
</div>