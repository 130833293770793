import { Component, OnInit, Inject, Optional } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { EnduserService } from 'src/app/core/services/enduser.service';
import { StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {
  availableToCopy = false

  constructor(
    private storeService: StoreService,
    private commonService: CommonService,
    private endUserService: EnduserService,
    private router: Router,
    @Optional() public dialogRef: MatDialogRef<MetadataComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  colorControl = new UntypedFormControl('');
  filteredColors: any = [];
  selectedColors: any = [];
  primaryColor = 0
  secondaryColor = 0
  tertiaryColor = 0
  teamStoreKeywords = ''
  isBestFundRaiser = false
  brandControl = new UntypedFormControl('');
  filteredBrands: any = [];
  selectedBrands: any = [];

  sportControl = new UntypedFormControl('');
  filteredSports: any = [];
  selectedSports: any = [];

  storeId
  teamStoreData
  colorList = []
  primaryColorList = []
  secondaryColorList = []
  tertiaryColorList = []
  sportList = []
  brandList = []

  ngOnInit() {
    if (this.data && this.data.storeId) {
      this.storeId = this.data.storeId
    } else {
      this.storeId = parseInt(localStorage.getItem("teamStoreId"));
    }
    this.getTeamstore()
    this.getBrands()
    this.getSports()
    this.getColors()

    this.filteredColors = this.colorControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this._filter(name as string, 'color') : this.colorList.filter(option => this.selectedColors.findIndex(color => color.id == option.id) == -1);
      }),
    );
    this.filteredBrands = this.brandControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this._filter(name as string, 'brand') : this.brandList.filter(option => this.selectedBrands.findIndex(brand => brand.id == option.id) == -1);
      }),
    );
    this.filteredSports = this.sportControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this._filter(name as string, 'sport') : this.sportList.filter(option => this.selectedSports.findIndex(sport => sport.id == option.id) == -1);
      }),
    );
  }

  getBrands() {
    this.storeService.getAllBrands().subscribe((res: any) => {
      this.brandList = res.data
      this.brandControl.setValue('')
    })
  }

  getSports() {
    this.storeService.getAllMarkets().subscribe((res: any) => {
      this.sportList = res.data
      this.sportControl.setValue('')
    })
  }

  getColors() {
    this.endUserService.getTeamStoreColors().subscribe((res: any) => {
      this.primaryColorList = res.data.primaryColors;
      this.secondaryColorList = res.data.secondaryColors;
      this.tertiaryColorList = res.data.tertiaryColors;
      this.colorControl.setValue('')
    })
  }

  saveMetadata() {
    let data = {
      teamStoreId: this.storeId,
      teamStoreBrands: this.selectedBrands.map(item => item.id),
      teamStoreSports: this.selectedSports.map(item => item.id),
      primaryColorId: this.primaryColor,
      secondaryColorId: this.secondaryColor,
      tertiaryColorId: this.tertiaryColor,
      teamStoreKeywords: this.teamStoreKeywords,
      isBestFundRaiser: this.isBestFundRaiser,
      availableToCopy: this.availableToCopy
    }
    this.storeService.updateStoreMetadata(data).subscribe(res => {
      this.commonService.openSuccessSnackBar("Metadata updated successfully", "")
      const url = this.commonService.createUrl(this.router.url, '/orderreceipt', 2);
      if (this.data && this.data.storeId) {
        this.closeDialog(true)
      } else {
        this.router.navigateByUrl(url);
      }
    }, err => {
      this.commonService.openErrorSnackBar("Unable to update metadata", "")
    })
  }

  getTeamstore() {
    this.storeService.getStore(this.storeId).subscribe((res: any) => {
      this.teamStoreData = res.data
      this.setMetadata()
    })
  }

  setMetadata() {
    this.selectedBrands = this.teamStoreData.teamStoreBrands || []
    this.selectedSports = this.teamStoreData.teamStoreSports || []
    this.primaryColor = this.teamStoreData.teamStoreColors[0] ? this.teamStoreData.teamStoreColors[0].primaryColorId : null
    this.secondaryColor = this.teamStoreData.teamStoreColors[0] ? this.teamStoreData.teamStoreColors[0].secondaryColorId : null
    this.tertiaryColor = this.teamStoreData.teamStoreColors[0] ? this.teamStoreData.teamStoreColors[0].tertiaryColorId : null
    this.teamStoreKeywords = this.teamStoreData.teamStoreKeywords || ''
    this.isBestFundRaiser = this.teamStoreData.isBestFundRaiser || false
    this.availableToCopy = this.teamStoreData.availableToCopy || false
    this.brandControl.setValue('')
    this.sportControl.setValue('')
  }

  displayFn(obj): string {
    return obj && obj.name ? obj.name : '';
  }

  private _filter(name: string, list) {
    const filterValue = name.toLowerCase();

    switch (list) {
      case 'color':
        return this.colorList.filter(option => option.name.toLowerCase().includes(filterValue) && !this.selectedColors.find(color => color.id == option.id));
      case 'brand':
        return this.brandList.filter(option => option.name.toLowerCase().includes(filterValue) && !this.selectedBrands.find(brand => brand.id == option.id));
      case 'sport':
        return this.sportList.filter(option => option.name.toLowerCase().includes(filterValue) && !this.selectedSports.find(sport => sport.id == option.id));
      default:
        return []
    }
  }

  addMetadata(event, type) {
    switch (type) {
      case 'brand':
        this.selectedBrands.push(event.option.value)
        this.brandControl.setValue('')
        break;
      case 'sport':
        this.selectedSports.push(event.option.value)
        this.sportControl.setValue('')
        break;
      case 'color':
        this.selectedColors.push(event.option.value)
        this.colorControl.setValue('')
        break;
    }
  }

  removeMetadata(item, type) {
    let index = 0
    switch (type) {
      case 'brand':
        index = this.selectedBrands.findIndex(option => option.id == item.id)
        this.selectedBrands.splice(index, 1)
        break;
      case 'sport':
        index = this.selectedBrands.findIndex(option => option.id == item.id)
        this.selectedSports.splice(index, 1)
        break;
      case 'color':
        index = this.selectedBrands.findIndex(option => option.id == item.id)
        this.selectedColors.splice(index, 1)
        break;
    }
  }

  closeDialog(res = false) {
    this.dialogRef.close(res)
  }
}