import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';


import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { ColorPickerModule } from 'ngx-color-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { SharedRoutingModule } from './shared-routing.module';

import { DesignerComponent } from './designer/designer.component';
import { Layout1Component } from './layoutComponents/layout1/layout1.component';
import { Layout2Component } from './layoutComponents/layout2/layout2.component';
import { Layout1Component as Layout1ComponentPreview } from './layoutComponentsPreview/layout1/layout1.component';
import { Layout2Component as Layout2ComponentPreview } from './layoutComponentsPreview/layout2/layout2.component';
import { ViewLayout1Component } from './viewStoreComponents/view-layout1/view-layout1.component';
import { ViewLayout2Component } from './viewStoreComponents/view-layout2/view-layout2.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { StoreListComponent } from './store-list/store-list.component';
import { ViewStoreComponent } from './view-store/view-store.component';


import { StoreDetailsComponent } from './store-details/store-details.component';
import { ProductListingComponent } from './store-details/product-listing/product-listing.component'
import { ActiveArtComponent } from './store-details/active-art/active-art.component';
import { ReviewProductsComponent } from './store-details/review-products/review-products.component';
import { CouponsDiscountsComponent } from './store-details/coupons-discounts/coupons-discounts.component';
import { StoreStyleEditorComponent } from './store-details/store-style-editor/store-style-editor.component';
import { StoreLayoutComponent } from './store-details/store-layout/store-layout.component';
import { StoreSummaryComponent } from './store-details/store-summary/store-summary.component';
import { StoreProductCategoriesComponent } from './store-details/store-product-categories/store-product-categories.component';

import { StoreSettingsComponent } from './store-settings/store-settings.component';
import { StoreNameComponent } from './store-settings/store-name/store-name.component';
import { PrivacySettingsComponent } from './store-settings/privacy-settings/privacy-settings.component';
import { SocialMediaComponent } from './store-settings/social-media/social-media.component';
import { StoreContactComponent } from './store-settings/store-contact/store-contact.component';
import { StoreNotificationsComponent } from './store-settings/store-notifications/store-notifications.component';
import { StoreCommissionComponent } from './store-settings/store-commission/store-commission.component';
import { StoreProductdisplayComponent } from './store-settings/store-productdisplay/store-productdisplay.component';
import { StoreBrandingComponent } from './store-settings/store-branding/store-branding.component';
import { StoreDomainComponent } from './store-settings/store-domain/store-domain.component';
import { StoreDeveloperComponent } from './store-settings/store-developer/store-developer.component';
import { StoreClosedPageComponent } from './store-settings/store-closed-page/store-closed-page.component';
import { ShippingMethodsComponent } from './store-settings/shipping-methods/shipping-methods.component';
import { CheckoutSettingsComponent } from './store-settings/checkout-settings/checkout-settings.component';
import { SeoSettingsComponent } from './store-settings/seo-settings/seo-settings.component';
import { DeveloperSettingsComponent } from './store-settings/developer-settings/developer-settings.component';
import { StorePickupComponent } from './store-settings/store-pickup/store-pickup.component';
import { ProductionScheduleComponent } from './store-settings/production-schedule/production-schedule.component';
import { StorePoliciesComponent } from './store-settings/store-policies/store-policies.component';
import { CustomOrderFieldsComponent } from './store-settings/custom-order-fields/custom-order-fields.component';
import { DesignerSettingsComponent } from './store-settings/designer-settings/designer-settings.component';
import { CountDownTimerComponent } from './count-down-timer/count-down-timer.component';

import { ProductViewComponent } from './product-view/product-view.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { VerifyArtComponent } from './verify-art/verify-art.component';
import { VerifyTeamstoreComponent } from './verify-teamstore/verify-teamstore.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { QuillModule } from 'ngx-quill-v2';
import { VerifyArtDetailComponent } from './verify-art-detail/verify-art-detail.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ViewArtComponent } from './view-art/view-art.component';
import { LayoutForScreenCaptureComponent } from './layoutComponentsPreview/layout-for-screen-capture/layout-for-screen-capture.component';
import { ResponseWindowComponent } from './response-window/response-window.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { NanFormComponent } from './nan-form/nan-form.component';
import { PdfModalComponent } from './pdf-modal/pdf-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WebOrderDetailsComponent } from './web-order-details/web-order-details.component';

@NgModule({
  declarations: [
    DesignerComponent,
    Layout1Component,
    Layout2Component,
    Layout1ComponentPreview,
    Layout2ComponentPreview,
    ViewLayout1Component,
    ViewLayout2Component,
    ProductSelectionComponent,
    StoreListComponent,
    ViewStoreComponent,

    StoreDetailsComponent,
    ProductListingComponent,
    ActiveArtComponent,
    ReviewProductsComponent,
    CouponsDiscountsComponent,
    StoreLayoutComponent,
    StoreSummaryComponent,
    StoreProductCategoriesComponent,
    StoreStyleEditorComponent,
    LayoutForScreenCaptureComponent,
    StoreSettingsComponent,
    StoreNameComponent,
    PrivacySettingsComponent,
    SocialMediaComponent,
    StoreNotificationsComponent,
    StoreCommissionComponent,
    StoreContactComponent,
    StoreNotificationsComponent,
    StoreProductdisplayComponent,
    StoreClosedPageComponent,
    ShippingMethodsComponent,
    StoreBrandingComponent,
    StoreDomainComponent,
    StoreDeveloperComponent,
    StoreClosedPageComponent,
    CheckoutSettingsComponent,
    SeoSettingsComponent,
    DeveloperSettingsComponent,
    StorePickupComponent,
    ProductionScheduleComponent,
    StorePoliciesComponent,
    DesignerComponent,
    CustomOrderFieldsComponent,
    DesignerSettingsComponent,
    CountDownTimerComponent,

    ProductViewComponent,
    CartComponent,
    CheckoutComponent,
    VerifyArtComponent,
    VerifyTeamstoreComponent,
    VerifyUserComponent,
    CountDownTimerComponent,
    VerifyArtDetailComponent,
    ViewArtComponent,
    ResponseWindowComponent,
    OrderDetailsComponent,
    NanFormComponent,
    PdfModalComponent,
    WebOrderDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedRoutingModule,
    ReactiveFormsModule,

    MatToolbarModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatGridListModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatListModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatChipsModule,
    MatTooltipModule,
    QuillModule,

    ColorPickerModule,
    DragDropModule,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDialogModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatToolbarModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatGridListModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatListModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatChipsModule,
    QuillModule,

    ColorPickerModule,
    DragDropModule,
    NgxMaterialTimepickerModule,
    QuillModule,


    DesignerComponent,
    Layout1Component,
    Layout2Component,
    Layout1ComponentPreview,
    Layout2ComponentPreview,
    ViewLayout1Component,
    ViewLayout2Component,
    ProductSelectionComponent,
    StoreListComponent,
    ViewStoreComponent,

    StoreDetailsComponent,
    ProductListingComponent,
    ActiveArtComponent,
    ReviewProductsComponent,
    CouponsDiscountsComponent,
    StoreLayoutComponent,
    StoreSummaryComponent,
    StoreProductCategoriesComponent,
    StoreStyleEditorComponent,

    StoreSettingsComponent,
    StoreNameComponent,
    PrivacySettingsComponent,
    SocialMediaComponent,
    StoreNotificationsComponent,
    StoreCommissionComponent,
    StoreContactComponent,
    StoreNotificationsComponent,
    StoreProductdisplayComponent,
    StoreClosedPageComponent,
    ShippingMethodsComponent,
    StoreBrandingComponent,
    StoreDomainComponent,
    StoreDeveloperComponent,
    StoreClosedPageComponent,
    CheckoutSettingsComponent,
    SeoSettingsComponent,
    DeveloperSettingsComponent,
    StorePickupComponent,
    ProductionScheduleComponent,
    StorePoliciesComponent,
    DesignerComponent,
    CustomOrderFieldsComponent,
    DesignerSettingsComponent,
    CountDownTimerComponent,

    ProductViewComponent,
    CartComponent,
    CheckoutComponent
  ]
})
export class SharedModule { }
