<div class="view-wrapper">
  <div class="error-page" *ngIf="!selectedComponent && !loading">
    <header class="error-page__header">
      <h1 class="error-page__title nolinks">Page Not Found</h1>
    </header>
    <p class="error-page__message">
      The page you are looking for could not be found.
    </p>
  </div>


  <div *ngIf="loading" style="width: 100%; text-align: center; color: darkgray; padding: 10% 0%">
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>


  <app-layout1-preview (isStoreVerified)="isStoreVerified($event)" *ngIf="selectedComponent == 1"
    [teamStoreId]="teamStoreId" [layoutData]="layoutData"></app-layout1-preview>
  <app-layout2-preview (isStoreVerified)="isStoreVerified($event)" *ngIf="selectedComponent == 2"
    [teamStoreId]="teamStoreId" [layoutData]="layoutData"></app-layout2-preview>
</div>
<br>
<!-- <div *ngIf="!isInteracted && !loading && selectedComponent" style="text-align: center;position: fixed;right: 2em;top: 3em;z-index: 200;">
  
  <button mat-raised-button color="warn" data-toggle="modal" data-target="#rejectStoreModal">Reject</button>&nbsp;&nbsp;
  <button mat-raised-button color="primary" (click)="updateStatus(2)">Approve</button>
</div> -->
<br><br>


<!-- <div class="modal-dialog"> -->
<!-- <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeArtModal()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form #form="ngForm">
          <mat-form-field style="width: 80%">
            <mat-label>Add Link</mat-label>
            <input type="text" matInput name="name" id="name" [(ngModel)]="imageUrl" />
          </mat-form-field>
          <button type="button" class="btn addArtBtn" (click)="addImageLink(imageUrl)">
            Add link
          </button>
        </form>
      </div>
    </div>
  </div> -->

<div class="modal fade" id="rejectStoreModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Rejection Reason</h5>
        <button type="button" class="close" (click)="cancelRejection()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="storeRejectionForm">
          <mat-form-field style="width: 100%;">
            <mat-label>Reason</mat-label>
            <mat-select formControlName="amRejectionCodes" multiple>
              <mat-option *ngFor="let reason of rejectionCodes" [value]="reason.id">{{reason.code}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br>
          <mat-form-field style="width: 100%;">
            <mat-label>Comment</mat-label>
            <textarea matInput formControlName="amRejectionText"></textarea>
          </mat-form-field>
          <button type="button" class="btn btn-primary" (click)="confirmRejection()">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>