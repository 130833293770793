<br><br>
<div class="row  justify-content-center">
  <mat-card class="col-md-4">

    <img width="100%" height="45%" src="../../../../assets/images/ares-userlogo.png">
    <br><br>
    <h2>Log In</h2>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-form-field style="width: 100%;">
        <mat-label>Username</mat-label>
        <input type="email" matInput formControlName="email">
        <mat-error *ngIf="loginForm.get('email').errors.required">
          Email is required
        </mat-error>
        <!-- <mat-error *ngIf="loginForm.get('email').errors.pattern">
          Please enter a valid email address
        </mat-error> -->
      </mat-form-field>

      <mat-form-field style="width: 100%;">
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password">
        <mat-error *ngIf="loginForm.get('password').errors.required">
          Password is required
        </mat-error>
      </mat-form-field>

      <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary" id="btnLogin">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Login
      </button>
      <a routerLink="/register" class="btn btn-link">Register</a>
      </div>
    </form>   

  </mat-card>
</div>
