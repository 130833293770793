<div class="grid-container">
  <div class="cart-detail">
    <div class="title"> YOUR CART </div>
    <div class="cart-list-container" *ngIf="!hidePage">
      <div class="cart" *ngFor="let product of cartItems?.cartItemsInfo; let i = index">
        <div class="prod-img" 
        [ngStyle]="{'background-image':'url('+(product?.productImages ? product?.productImages[0] : noImagePlaceholder)+')'}">
          <!-- <img [src]="product?.productImages ? product?.productImages[0] : noImagePlaceholder" /> -->
        </div>
        <div class="prod-detail">
          <p class="prod-name">
            {{product?.displayName ? product?.displayName : product?.productName}}
          </p>
          <p class="style-name" *ngIf="product?.styleName">
            {{product?.styleName || ""}}
          </p>
          <p class="order-type">
            {{product?.teamStoreId ? "Store Order (" + (product?.teamStoreName || " - ") + ")" : "Web Order"}}
          </p>
          <table class="prod-info">
            <tr>
              <th>Color :</th>
              <td>{{product?.cartItemGrids[0]?.color}}</td>
            </tr>
            <tr>
              <th>Sizes :</th>
              <td>
                <ng-container *ngFor="let p of product?.cartItemGrids">
                  <div *ngIf="p.quantity && p.quantity>0" class="w-100 p-1">{{p.size}}</div>
                </ng-container>
              </td>
              <td class="numbers">
                <ng-container *ngFor="let p of product?.cartItemGrids">
                  <div *ngIf="p.quantity && p.quantity>0" class="w-100 p-1">({{p.quantity}})</div>
                </ng-container>
              </td>
            </tr>
            <tr>
              <th colspan="2">
                Total Quantity :
              </th>
              <td class="numbers">
                {{product.quantity}}
              </td>
            </tr>
            <tr>
              <th colspan="2">
                Total Price :
              </th>
              <td class="numbers total-price">
                ${{product.totalPrice}}
              </td>
            </tr>
          </table>
          <div class="buttons">
            <button class="btn btn-sm mt-2 mr-2 btn-outline-primary px-3" (click)="addMore(product)">
              Edit
            </button>
            <button class="btn btn-sm mt-2 mr-2 btn-outline-primary px-3" (click)="deleteItem(product,i)">
              Remove Item
            </button>
            <button *ngIf="product.isPersonalize" class="btn btn-sm mt-2 mr-2 btn-outline-primary px-3"
              (click)="openPersonalizationModal(product)">
              View Personalization
            </button>
          </div>
        </div>
        <!-- <div class="total-price">
          ${{product.totalPrice}}
        </div> -->
      </div>
    </div>
  </div>
  <div class="sub-total">
    <div class="title"> SUB TOTAL </div>
    <p class="sub-total-amount"  *ngIf="!hidePage">
      ({{cartItems? cartItems.cartItemsInfo.length : 0}}) Items: ${{totalAmount}}
    </p>
    <div class="buttons"  *ngIf="!hidePage">
      <button class="btn btn-sm btn-primary" (click)="goBackToProductSelection()">
        Continue Shopping
      </button>
      <button class="btn btn-sm btn-primary" (click)="checkout()" [disabled]="cartItems?.cartItemsInfo?.length == 0">
        Check Out
      </button>
    </div>
  </div>
</div>


<div class="modal fade" id="personalizationModal" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="togglePersonalizationModal()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <table class="table">
          <thead style="padding: 5px;">
            <tr>
              <th width="30%">Name</th>
              <th width="15%">Number</th>
              <th width="10%">Size</th>
              <th width="15%">Qty</th>
            </tr>
          </thead>
          <tbody style="max-height: 200px;overflow-y: scroll;">
            <ng-container *ngFor="let item of cartGridItems">
              <tr *ngIf="item.personalize">
                <td>
                  <span>
                    <label>{{item?.name}}</label>
                  </span>
                </td>
                <td>
                  <span>
                    <label>{{item?.number}}</label>
                  </span>
                </td>
                <td>
                  <span>
                    <label>{{item?.size}}</label>
                  </span>
                </td>
                <td>
                  <span>
                    <label>{{item?.quantity}}</label>
                  </span>
                </td>
              </tr>
            </ng-container>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>