<div class="grid-container"
  [ngStyle]="{'background-color': layoutData.layout.backgroundColor,'color': layoutData.layout.fontColor}">
  <div class="banner" *ngIf="layoutData.disclaimer"
    [ngStyle]="{'background-color': layoutData.banner?.backgroundColor,'color': layoutData.banner?.fontColor}">
    {{layoutData.disclaimer}}
  </div>
  <div class="header"
    [ngStyle]="{'background-color': layoutData.header.backgroundColor,'color': layoutData.header.fontColor}">
    <!-- <div class="title"></div> -->
    <div class="logo">
      <img src={{layoutData.header.logoUrl}} *ngIf="layoutData.header.logoUrl != ''"
        [ngStyle]="{'height': layoutData.header.logoHeight}">
    </div>
  </div>
  <div class="timer">
    <app-count-down-timer [layoutData]="layoutData" [teamStoreData]="teamStoreData"></app-count-down-timer>
  </div>
  <div class="content">
    <div class="category" *ngFor="let category of productsByCategory;let j=index">
      <div class="category-name">
        {{category.categoryName}}
      </div>
      <div class="category-products">
        <div class="prod-card" *ngFor="let product of category.products; let i = index">
          <div class="prod-status" (click)="resetProductStatus(product.mapCode)"
            title="{{teamStoreData?.stage==9 ? 'Click to reset status' : ''}}"
            [ngClass]="{'approved': product.teamstoreGarmentStatus == 1, 'rejected': product.teamstoreGarmentStatus == 2}">
            {{product.teamstoreGarmentStatus == 1 ? "&#10003;" : product.teamstoreGarmentStatus == 2 ? "&#10006;" : ""}}
          </div>
          <div class="overlay" *ngIf="product.teamstoreGarmentStatus == 0 && teamStoreData?.stage == 9">
            <div class="info">
              <p class="fundraising">Fundraising: ${{product.fundRaisingEarned}}</p>
              <p class="personalization" *ngIf="product.personalizations?.length">
                Personalization:
                <!-- <span *ngFor="let p of product.personalizations;let i=index">
                  {{product.personalizations.length-1 > i ? p.personalizedType + ", " : p.personalizedType}}
                </span> -->
                <span>{{mapFn(product.personalizations)}}</span>
              </p>
              <p class="personalization" *ngIf="!product.personalizations?.length">
                Personalization: Not Personalized
              </p>
            </div>
            <div class="buttons" *ngIf="!isCloseDatePassed(teamStoreData?.closeDate)">
              <button class="btn btn-danger" (click)="showProductRejectPopup(product.mapCode)" title="Reject Product">
                &#10006;
              </button>
              <button class="btn btn-success" (click)="approveProduct(product.mapCode)" title="Approve Product">
                &#10003;
              </button>
            </div>
          </div>
          <div class="prod-image" (click)="clickEvent('productSelected',product.id,product.colorId,product.mapCode)"
            [ngStyle]="{'background-image':'url('+(selectedImage[j][i] || product.images[0] || noImagePlaceholder)+')'}">
          </div>
          <div class="prod-thumbnail">
            <div class="thumbnail" *ngFor="let img of product.images" [ngStyle]="{'background-image':'url('+img+')'}"
              (click)="selectedImage[j][i] = img;">
            </div>
          </div>
          <div class="prod-detail" (click)="clickEvent('productSelected',product.id,product.colorId,product.mapCode)">
            <p class="prod-name"> {{product.name}} </p>
            <p class="prod-price"> ${{product.price}} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer"
    [ngStyle]="{'background-color': layoutData.footer.backgroundColor,'color': layoutData.footer.fontColor}">
    <div class="contents">
      <div class="contact-us">
        <label>CONTACT US</label>
        <div class="address">
          <mat-icon aria-hidden="true" data-mat-icon-type="font">place</mat-icon>
          <p>3700 LACON RD SUITE A.<br> HILLIARD, OH 43026-1207</p>
        </div>
        <div class="phone">
          <mat-icon aria-hidden="true" data-mat-icon-type="font">phone</mat-icon>
          <p>800-439-8614</p>
        </div>
        <div class="email">
          <mat-icon aria-hidden="true" data-mat-icon-type="font">mail</mat-icon>
          <p>{{'info@areswear.com'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- *ngIf="teamStoreData?.stage == 9" -->
<div class="store-buttons" *ngIf=" !isCloseDatePassed(teamStoreData?.closeDate) && teamStoreData.stage !== 20 && teamStoreData.stage !== 19 && teamStoreData.stage !== 10 || teamStoreData?.stage == 9">
  <button class="btn btn-info store-button" data-toggle="modal"  data-target="#infoModal">How to approve your store</button>
  <!-- *ngIf="showStoreRejectButton" -->
  <button class="btn btn-danger m-3 store-button" (click)="showRejectStoreModal()">
    <span> Reject Store</span>
    <span style="font-size: small">I Need Changes</span>
  </button>
    <!-- *ngIf="showStoreApproveButton" -->
  <button class="btn btn-success store-button" (click)="showStoreApproveAlert()"  [disabled]="isAnyRejected()"
  [title]="isAnyRejected() ? 'Changes to your store are required, please click reject store!' : ''" >
  <span>Approve Store</span>
  <span style="font-size: small">No Turning Back</span>
 </button>
</div>


<!-- Reject Product Modal -->
<div class="modal fade" id="rejectProductModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Rejection Reason</h5>
        <button type="button" class="close" (click)="closePopup()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="rejectProductGroup">
          <mat-form-field style="width: 100%;">
            <mat-label>Reason</mat-label>
            <mat-select formControlName="rejectionCodes" multiple>
              <ng-container *ngFor="let reason of rejectionCodes">
                <!-- group type 1 for product -->
                <mat-option *ngIf="reason.codeType == 1" [value]="reason.id">{{reason.code}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <br>
          <mat-form-field style="width: 100%;">
            <mat-label>Comment</mat-label>
            <textarea matInput formControlName="rejectionText"></textarea>
          </mat-form-field>
          <button type="button" [disabled]="!rejectProductGroup.valid" class="btn btn-primary" (click)="rejectProduct()">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Reject Store Modal -->
<div class="modal fade" id="rejectStoreModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Rejection Reason</h5>
        <button type="button" class="close" (click)="closePopup()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="rejectStoreGroup">
          <mat-form-field style="width: 100%;">
            <mat-label>Reason</mat-label>
            <mat-select formControlName="rejectionCode" multiple>
              <ng-container *ngFor="let reason of rejectionCodes">
                <!-- code type 2 for store -->
                <mat-option *ngIf="reason.codeType == 2" [value]="reason.id">{{reason.code}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <br>
          <mat-form-field style="width: 100%;">
            <mat-label>Comment</mat-label>
            <textarea matInput formControlName="rejectionReason"></textarea>
          </mat-form-field>
          <div class="reference-file-upload">
            <p class="label w-100">Upload Reference</p>
            <div [ngStyle]="{'background-image':'url('+url+')'}" class="reference-files"
              *ngFor="let url of referenceFiles;let i=index">
              <div class="remove-reference-icon" (click)="removeReferenceFile(i)">&times;</div>
            </div>
            <img src="../../../../assets/images/add-image.png" class="upload-img reference-files"
              *ngIf="referenceFiles.length<5" (click)="referenceFile.click()" /><img />
            <input #referenceFile type="file" id="referenceFile" style="display: none;"
              (change)="uploadReference($event.target.files)">
          </div>
          <button type="button" [disabled]="!rejectStoreGroup.valid" class="btn btn-primary mr-2" (click)="rejectStore()">
            Reject Store
          </button>
          <button type="button" class="btn btn-danger" (click)="closePopup()">
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- info Modal -->
<div class="modal fade show" id="infoModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Store Approval</h5>
        <button type="button" class="close" (click)="closePopup()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <p class="store-name font-weight-bold">
          {{teamStoreData?.name}}
        </p>
        <div class="notes">
          <label>Directions: </label>
          <!-- <p>
            Hover over each product to apply approval or rejection status. Green check is approve, red X is reject.
            Please select any/all necessary rejection reason and comment if applicable. Please be as specific as
            possible. Clicking on the status icon on the top right corner of each product will reset the approval
            status. Once all items are marked, there will be a final approval or rejection on the store in its entirety.
            If there are rejected items, you will only be able to reject the store. If all items are approved you have
            the option to approve or reject, again selecting the proper reason and comment. Only you can approve your
            store and will not open until this is complete. Please note, once approved NO CHANGES CAN BE MADE.
          </p> -->
          <p>
            1.Please review your store and each product within its entirety. If you approve, please click the Approve Store button.
             <br> <b>Please note, once approved NO CHANGES CAN BE MADE.</b> <br> 
             If changes are required, please click the red X on the product to be updated. You will be prompted to select any/all reasons and leave a comment. 
             Being as specific as possible will ensure our team will make the required changes quickly and efficiently.
              Finally, click the Reject Store button to send the request to our team
          </p>
          <!-- <ul>
            <li>Apply status to all products to approve or reject store.</li>
            <li>Click on the status icon on top right corner to reset product status.</li>
          </ul> -->
        </div>
        <p class="shipping-preference">
          <span class="font-weight-bold"> Shipping Preference: </span>
          {{
          teamStoreData?.shippingPreference == 1 ? "Individual Shipping" :
          teamStoreData?.shippingPreference == 2 ? "Bulk Shipping" :
          teamStoreData?.shippingPreference == 3 ? "Bulk Pickup" : ""
          }}
        </p>
        <p class="shipping-preference-info">
          {{
          teamStoreData?.shippingPreference == 1 ? "Orders will be shipped directly to each purchaser" :
          teamStoreData?.shippingPreference == 2 ? "The individually sorted orders will be shipped to you to pass out to
          your group" :
          teamStoreData?.shippingPreference == 3 ? "You will be able to pick up the individually sorted orders to pass
          out to your group" : ""
          }}
        </p>
        <p class="shipping-cost" *ngIf="teamStoreData?.shippingPreference == 1">
          <span class="font-weight-bold">Shipping Cost:</span> ${{teamStoreData?.estimatedIndividualShippingCharge}}
        </p>
        <p class="ship-to-address" *ngIf="teamStoreData?.shippingPreference != 1">
          <span class="font-weight-bold">Ship To Address:</span> <br><br>
          {{
          teamStoreData?.shipToAddress + ", " +
          teamStoreData?.shipToAddress1 + ", " +
          teamStoreData?.shipToCity + ", " +
          getState(teamStoreData?.shipToState).name + ", " +
          teamStoreData?.shipToZipCode
          }}
        </p>
        <p class="close-date">
          <span class="font-weight-bold">Close Date:</span> {{teamStoreData?.closeDate | date:'MM/dd/yyyy'}}
        </p>
        <!-- <p class="funrasing-total">
              Total Fundraising: ${{teamStoreData?.fundRaisingEarned}}
          </p> -->

      </div>
    </div>
  </div>
</div>