<div class="grid-container">
  <div class="prod-img" *ngIf="product?.productInfo?.images">
    <div
      class="img-container"
      (mousemove)="zoomIn($event)"
      (mouseout)="zoomOut()"
      [ngStyle]="{
        'background-image':
          'url(' + product?.productInfo?.images[0] || noImagePlaceholder + ')'
      }"
    ></div>
    <div class="thumbnail-container">
      <div
        class="thumbnail"
        *ngFor="let image of product?.productInfo?.images"
        (click)="onImgClick(image)"
        [ngStyle]="{ 'background-image': 'url(' + image + ')' }"
      ></div>
    </div>
      <div class="desc-container" >
        <div class="desc">{{product?.productInfo?.description}}
        </div>

      </div>
  </div>
  <div class="prod-img" *ngIf="!product?.productInfo?.images">
    <div
      class="img-container"
      (mousemove)="zoomIn($event)"
      (mouseout)="zoomOut()"
      [ngStyle]="{
        'background-image':
          'url(' + product?.productInfo?.variants[0]?.images[0]?.src ||
          noImagePlaceholder + ')'
      }"
    ></div>
    <div class="thumbnail-container">
      <div
        class="thumbnail"
        *ngFor="let image of product?.productInfo?.variants[0]?.images"
        (click)="onImgClick(image?.src)"
        [ngStyle]="{ 'background-image': 'url(' + image?.src + ')' }"
      ></div>
    </div>
  </div>
  <div class="prod-detail">
    <div class="detail-container row">
      <div class="prod-name-container col-md-6 col-sm-12">
        <p class="prod-name">
          {{ product?.productInfo?.displayName || product?.productInfo?.name }}
          {{
            product?.productInfo?.styleName
              ? "(" + product?.productInfo?.styleName.split(",")[0] + ")"
              : ""
          }}
        </p>
        <p class="color-price">
          <span>
            {{ product?.productInfo?.variants[0]?.name }}
          </span>
          <span class="ml-3" *ngIf="purchaseType == 3">
            ${{
              purchaseType == 3
                ? product?.productInfo?.totalPrice
                : product?.productInfo?.variants[0].sizeVariants[0].sellingPrice
            }}
          </span>
        </p>
      </div>
      <div class="total-price-container col-md-6 col-sm-12">
        <button style="border: none; position: absolute;  top: 96%;  right: 4%;" *ngIf="purchaseType==2 && !cartId"  [disabled]="isCopied" (click)="copyUrl()"> <img style="height: 38px;" src="../../../assets/images/copylink.png"> <span *ngIf="!isCopied">Copy this URL</span> <span *ngIf="isCopied">Copied this URL</span> </button>
        <p class="total-price" *ngIf="!decoPriceLoading">
          {{
            purchaseType == 3
              ? "Total $ " + calculateTotalPrice()
              : isDecorated() && decoPrice
              ? "Total $ " + calculateTotalPrice()
              : isDecorated() == false
              ? "Total $ " + quantityGroup?.get("price").value
              : "Complete settings to view price"
          }}
        </p>
        <p style="border: none; position: absolute;  top: 57%;  right: 4%;" *ngIf="purchaseType != 3 && !decoPriceLoading">   {{ "Unit Price  $ " +  unitPrice }}  </p>
        <!-- <div></div> -->
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          *ngIf="decoPriceLoading"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <!-- <table class="detail-table"> -->
    <!-- <tr>
        <th class="product-name" colspan="3">
          {{product?.productInfo?.displayName || product?.productInfo?.name}}
          {{product?.productInfo?.styleName ? "("+product?.productInfo?.styleName.split(',')[0]+")" : ""}}
        </th>
      </tr> -->
    <!-- <tr>
        <th>Categories</th>
        <td>:</td>
        <td>
          <ng-container *ngFor="let category of product?.productInfo?.categoryInfo;let i=index">
            {{i!=0 ? ", ":""}}{{category?.name}}
          </ng-container>
        </td>
      </tr> -->
    <!-- <tr>
        <th>Color</th>
        <td>:</td>
        <td>{{product?.productInfo?.variants[0]?.name}}</td>
      </tr> -->
    <!-- <tr>
        <th>Sizes</th>
        <td>:</td>
        <td>
          <ng-container *ngFor="let size of product?.productInfo?.variants[0]?.sizeVariants;let i=index">
            {{i!=0 ? ", ":""}}{{ size?.size }}
          </ng-container>
        </td>
      </tr> -->
    <!-- <tr>
        <th>Product Price</th>
        <td>:</td>
        <td>
          ${{
          purchaseType == 3 ?
          product?.productInfo?.totalPrice :
          product?.productInfo?.variants[0].sizeVariants[0].sellingPrice
          }}
        </td>
      </tr> -->
    <!-- <tr>
        <th>Total Quantity</th>
        <td>:</td>
        <td>{{quantityGroup?.get('quantity').value}}</td>
      </tr> -->
    <!-- <tr>
        <th>Total Price</th>
        <td>:</td>
        <td *ngIf="!decoPriceLoading">
          {{
          purchaseType == 3 ?
          "$" + calculateTotalPrice() :
          isDecorated() && decoPrice ?
          "$" + calculateTotalPrice() :
          isDecorated() == false ?
          "$" + quantityGroup?.get('price').value :
          "Please complete personalization / decoration settings to view price"
          }}
        </td>
        <td *ngIf="decoPriceLoading">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </td>
      </tr> -->
    <!-- </table> -->
    <div class="personalization-info">
      <div class="label">
        <p class="title">
          Add
          {{ personalizeValue ? "personalization details and " : "" }} quantity
          below
        </p>
        <a
          href="#"
          data-toggle="modal"
          data-target="#sizeChartModal"
          class="size-chart-btn"
          (click)="scrollToTop()"
          >View Size Chart</a
        >
      </div>
      <div class="personalizeTable">
        <form [formGroup]="quantityGroup">
          <table class="table" [ngClass]="{ 'long-table': personalizeValue }">
            <thead style="padding: 5px">
              <tr>
                <td width="19%">Size <span style="color: red">*</span></td>
                <td width="12%">Qty <span style="color: red">*</span></td>
                <ng-container *ngIf="personalizeValue">
                  <td width="5%">
                    Personalize <br />
                    &nbsp;
                  </td>
                  <td width="15%"> <!-- Adjusted width for Name column -->
                    <ng-container *ngIf="isPersonalized().isPersonalized && isPersonalized().isNamePersonalized">
                      Name
                    </ng-container>
                    <div style="display: inline-block;">
                      <span style="font-size: .9rem; color: #46e163;" *ngIf="showPersonalizationValue && isPersonalized().isNamePersonalized">Adds $7 <br/> Per Location</span>
                      <span style="font-size: .9rem; color: #46e163;" *ngIf="purchaseType!=3 && decoPrice?.namePrice>0">Adds $ {{decoPrice?.namePrice}} <br/> Per Location</span>
                    </div>
                  </td>
                  <td width="15%">
                    <ng-container *ngIf="isPersonalized().isPersonalized &&  isPersonalized().isNumberPersonalized">
                      Number
                    </ng-container>
                    <div style="display: inline-block;">
                      <span style="font-size: .9rem; color: #46e163;" *ngIf="showPersonalizationValue && isPersonalized().isNumberPersonalized">Adds $5 <br/>  Per Location</span>
                      <span style="font-size: .9rem; color: #46e163;" *ngIf="purchaseType!=3 && decoPrice?.numberPrice>0">Adds $ {{decoPrice?.numberPrice}} <br/> Per Location</span>
                    </div>
                  </td>

                </ng-container>
                <td width="12%"></td>
              </tr>
            </thead>

            <tbody>
              <tr
                formArrayName="cartItemGrids"
                *ngFor="
                  let cartItemGrids of quantityGroup?.get('cartItemGrids')[
                    'controls'
                  ];
                  let i = index
                "
              >
                <td>
                  <span [formGroupName]="i">
                    <select
                      style="width: 100%; height: 25px"
                      formControlName="size"
                      (change)="getTotalQuantity()"
                    >
                      <option value="0" hidden></option>
                      <option
                        *ngFor="
                          let size of product?.productInfo?.variants[0]
                            ?.sizeVariants
                        "
                        value="{{ size.sizeId }}"
                      >
                        {{ size.size }}
                        {{
                          purchaseType == 3 &&
                          product?.productInfo?.variants[0].sizeVariants[0]
                            .storePrice != size.storePrice
                            ? "(+$" +
                              (size.storePrice -
                                product?.productInfo?.variants[0]
                                  .sizeVariants[0].storePrice
                                | number : "1.0-2") +
                              ")"
                            : purchaseType != 3 &&
                              product?.productInfo?.variants[0].sizeVariants[0]
                                .sellingPrice != size.sellingPrice
                            ? "(+$" +
                              (size.sellingPrice -
                                product?.productInfo?.variants[0]
                                  .sizeVariants[0].sellingPrice
                                | number : "1.0-2") +
                              ")"
                            : ""
                        }}
                      </option>
                    </select>
                  </span>
                </td>
                <td>
                  <span [formGroupName]="i">
                    <input
                      style="width: 100%"
                      formControlName="quantity"
                      type="number"
                      min="1"
                      (input)="getTotalQuantity()" 
                    />
                  </span>
                </td>
                <ng-container *ngIf="personalizeValue">
                  <td style="text-align: center; vertical-align: bottom">
                    <span [formGroupName]="i">
                      <mat-checkbox
                        class="label-holder"
                        color="primary"
                        formControlName="personalize"
                        (change)="onPersonalizeCheck(i); getTotalQuantity()"
                      >
                      </mat-checkbox>
                    </span>
                  </td>
                  <td>
                    <span
                      [formGroupName]="i"
                      [hidden]="
                        quantityGroup.value.cartItemGrids[i].personalize ==
                        false
                      "
                    >
                      <input *ngIf="isPersonalized().isNamePersonalized"
                        style="width: 100%"
                        formControlName="name"
                        type="text"
                        onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || [32].includes(event.charCode)"
                        (input)="getTotalQuantity()"
                      />
                    </span>
                  </td>
                  <td>
                    <span
                      [formGroupName]="i"
                      [hidden]="
                        quantityGroup.value.cartItemGrids[i].personalize ==
                        false
                      "
                    >
                      <input *ngIf=" isPersonalized().isNumberPersonalized"
                        style="width: 100%"
                        formControlName="number"
                        type="number"
                        min="0"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        (input)="getTotalQuantity()"
                      />
                    </span>
                  </td>
                </ng-container>
                <td>
                  <mat-icon
                    mat-button
                    (click)="deleteRow(i)"
                    style="cursor: pointer"
                    >close</mat-icon
                  >
                  &nbsp;
                  <mat-icon
                    *ngIf="
                      i ===
                      quantityGroup?.get('cartItemGrids')['controls'].length - 1
                    "
                    mat-button
                    (click)="addRow()"
                    style="cursor: pointer"
                    >add</mat-icon
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="buttons">
        <button
          mat-raised-button
          color="primary"
          *ngIf="isPersonalized().isPersonalized && purchaseType != 3"
          data-toggle="modal"
          data-target="#personalizationProperties"
          (click)="scrollToTop()"
        >
          Personalization Settings
        </button>
        <button
          mat-raised-button
          color="primary"
          *ngIf="purchaseType == 2"
          data-toggle="modal"
          data-target="#decorationProperties"
          (click)="scrollToTop()"
        >
          Decoration Settings
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="backToStore()"
          *ngIf="!isEdit"
        >
          <!-- {{ purchaseType === 1 || purchaseType == 2 ? "Back to product list" : "Back to store" }} -->
          Continue Shopping
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="addToCart()"
          *ngIf="!isEdit"
        >
          Add To Cart
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="addToCart()"
          *ngIf="isEdit"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Size chart modal -->
<div
  class="modal fade"
  id="sizeChartModal"
  tabindex="-1"
  role="dialog"
  data-keyboard="false"
  data-backdrop="static"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document" style="max-width: 80vw">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn btn-danger btn-sm"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="view-size-chart">
          <img
            src="{{ obj.url }}"
            class="upload-size-chart-img"
            *ngFor="let obj of product?.productInfo?.sizeCharts"
          />
          <h4
            class="w-100 text-center m-3"
            *ngIf="!product?.productInfo?.sizeCharts?.length"
          >
            Size Chart Not Available
          </h4>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Personalization Settings Modal -->
<div
  class="modal fade"
  id="personalizationProperties"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Personalization Properties
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="getDecoPrice(true)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="properties">
          <div
            class="content-grid"
            *ngFor="
              let type of personalizationPropertiesForm.controls;
              let i = index
            "
            [formGroup]="type"
          >
            <div class="type">{{ type.value.name }}</div>
            <!-- <div class="position">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Position</mat-label>
                <mat-select
                  formControlName="positionId"
                  (selectionChange)="onDecoLocationChange(i, 'personalization')"
                >
                  <ng-container *ngFor="let position of decoGroup?.locations">
                    <mat-option
                      [value]="position.id"
                      [disabled]="isDecoLocationSelected(position.id)"
                    >
                      {{ position.name }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div> -->
            <!-- <div class="service">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Service</mat-label>
                <mat-select
                  formControlName="serviceId"
                  (selectionChange)="onDecoServiceChange(i, 'personalization')"
                >
                  <ng-container
                    *ngFor="
                      let service of decoGroupServices
                    "
                  >
                    <ng-container
                      *ngIf="
                        !(
                          service.decoGroupMethodId == 12 &&
                          type.value.personalizationTypeId == 1
                        )
                      "
                    >
                      <mat-option [value]="service.decoGroupMethodId">
                        {{ service.decoGroupMethodName }}
                      </mat-option>
                    </ng-container>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div> -->
            <!-- <div class="service">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Service</mat-label>
                <input  type="text"  readonly  matInput  formControlName="serviceId" />
              </mat-form-field>
            </div> -->
            <div class="color">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Color</mat-label>
                <mat-select formControlName="colorId">
                  <mat-option
                    *ngFor="let color of getInkColorsByService(i)"
                    [value]="color.id"
                  >
                    <!-- {{color.name}} -->
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <span>{{ color.name }}</span>
                      <div
                        style="
                          height: 20px;
                          width: 20px;
                          border: 1px solid black;
                        "
                        [style.background-color]="color.hexValue"
                      ></div>
                      <!-- <img src="https://picsum.photos/200/300" style="height:20px; width:20px;"> -->
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="size">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Size</mat-label>
                <mat-select formControlName="sizeId">
                  <mat-option
                    *ngFor="let size of getSizesByService(i)"
                    [value]="size.id"
                  >
                    {{ size.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="font">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Font</mat-label>
                <mat-select formControlName="fontId">
                  <mat-option
                    *ngFor="let font of getFontByService(i)"
                    [value]="font.id"
                  >
                    {{ font.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="getDecoPrice(true)"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="decorationProperties"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Decoration Properties
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="getDecoPrice(true)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="properties">
          <div
            class="content-grid"
            *ngFor="
              let decoration of decorationPropertiesForm.controls;
              let i = index
            "
            [formGroup]="decoration"
          >
            <!-- <div
              class="art"
              [ngStyle]="{
                'background-image': 'url(' + decoration.value.art + ')'
              }"
            ></div> -->
            <div class=" art col text-center background-check"
            style="display: flex; align-items: center; justify-content: center;">
            <img src={{decoration.value.art}} style="max-height: 100%; max-width: 100%;">
          </div>
            <!-- <div class="position">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Position</mat-label>
                <mat-select
                  formControlName="positionId"
                  (selectionChange)="onDecoLocationChange(i, 'deco')"
                >
                  <ng-container *ngFor="let position of decoGroup?.locations">
                    <mat-option
                      [value]="position.id"
                      [disabled]="isDecoLocationSelected(position.id)"
                    >
                      {{ position.name }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div> -->
            <div class="service">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Service</mat-label>
                <!-- <input *ngIf="decoration.get('serviceId').value === 1"
                  type="text"
                  readonly
                  matInput
                  value="Print"
                  formControlName="serviceId"
                />
                <input *ngIf="decoration.get('serviceId').value === 2"
                  type="text"
                  readonly
                  matInput
                  value="Embroidery"
                  formControlName="serviceId"
                /> -->
                <input  type="text"  readonly  matInput   formControlName="serviceName" />
              </mat-form-field>
            </div>


            <div class="color">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Color</mat-label>
                <mat-select  formControlName="colorId" readonly  multiple  >
                  <mat-option  [disabled]="true" selected
                    *ngFor="let color of decoration.get('colorId').value"
                    [value]="color"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <span>{{ color.name }}</span>
                      <div
                        style="
                          height: 20px;
                          width: 20px;
                          border: 1px solid black;
                        "
                        [style.background-color]="color.hexValue"
                      ></div>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="color-count">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Color Count</mat-label>
                <input
                  type="number"
                  readonly
                  matInput
                  formControlName="colorCount"
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <table class="table properties-table" *ngIf="false">
          <tbody>
            <tr
              *ngFor="
                let decoration of decorationPropertiesForm.controls;
                let i = index
              "
              [formGroup]="decoration"
            >
              <td>
                <div class="art">
                  <img src="{{ decoration.value.art }}" alt="Art" />
                </div>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Position</mat-label>
                  <mat-select
                    formControlName="positionId"
                    (selectionChange)="onDecoLocationChange(i, 'deco')"
                  >
                    <ng-container *ngFor="let position of decoGroup?.locations">
                      <mat-option
                        [value]="position.id"
                        [disabled]="isDecoLocationSelected(position.id)"
                      >
                        {{ position.name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Service</mat-label>
                  <mat-select
                    formControlName="serviceId"
                    (selectionChange)="onDecoServiceChange(i, 'deco')"
                  >
                    <ng-container *ngFor="let service of getDecoServices(i)">
                      <ng-container *ngIf="service.id != 11">
                        <mat-option [value]="service.id">
                          {{ service.name }}
                        </mat-option>
                      </ng-container>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Color</mat-label>
                  <mat-select
                    formControlName="colorId"
                    multiple
                    (selectionChange)="updateColorCount(i)"
                  >
                    <mat-option
                      *ngFor="let color of getDecoInkColors(i)"
                      [value]="color.id"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        "
                      >
                        <span>{{ color.name }}</span>
                        <div
                          style="
                            height: 20px;
                            width: 20px;
                            border: 1px solid black;
                          "
                          [style.background-color]="color.hexValue"
                        ></div>
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Color Count</mat-label>
                  <input
                    type="number"
                    readonly
                    matInput
                    formControlName="colorCount"
                  />
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="getDecoPrice(true)"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<!-- decoration settings Modal -->
<!-- <div
  class="modal fade"
  id="decorationProperties"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Decoration Properties
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="getDecoPrice(true)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="properties">
          <div
            class="content-grid"
            *ngFor="
              let decoration of decorationPropertiesForm.controls;
              let i = index
            "
            [formGroup]="decoration"
          >
            <div
              class="art"
              [ngStyle]="{
                'background-image': 'url(' + decoration.value.art + ')'
              }"
            ></div>
            <div class="position">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Position</mat-label>
                <mat-select
                  formControlName="positionId"
                  (selectionChange)="onDecoLocationChange(i, 'deco')"
                >
                  <ng-container *ngFor="let position of decoGroup?.locations">
                    <mat-option
                      [value]="position.id"
                      [disabled]="isDecoLocationSelected(position.id)"
                    >
                      {{ position.name }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="service">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Service</mat-label>
                <mat-select
                  formControlName="serviceId"
                  (selectionChange)="onDecoServiceChange(i, 'deco')"
                >
                  <ng-container *ngFor="let service of getDecoServices(i)">
                    <ng-container *ngIf="service.id != 11">
                      <mat-option [value]="service.id">
                        {{ service.name }}
                      </mat-option>
                    </ng-container>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="color">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Color</mat-label>
                <mat-select
                  formControlName="colorId"
                  multiple
                  (selectionChange)="updateColorCount(i)"
                >
                  <mat-option
                    *ngFor="let color of getDecoInkColors(i)"
                    [value]="color.id"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <span>{{ color.name }}</span>
                      <div
                        style="
                          height: 20px;
                          width: 20px;
                          border: 1px solid black;
                        "
                        [style.background-color]="color.hexValue"
                      ></div>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="color-count">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Color Count</mat-label>
                <input
                  type="number"
                  readonly
                  matInput
                  formControlName="colorCount"
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <table class="table properties-table" *ngIf="false">
          <tbody>
            <tr
              *ngFor="
                let decoration of decorationPropertiesForm.controls;
                let i = index
              "
              [formGroup]="decoration"
            >
              <td>
                <div class="art">
                  <img src="{{ decoration.value.art }}" alt="Art" />
                </div>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Position</mat-label>
                  <mat-select
                    formControlName="positionId"
                    (selectionChange)="onDecoLocationChange(i, 'deco')"
                  >
                    <ng-container *ngFor="let position of decoGroup?.locations">
                      <mat-option
                        [value]="position.id"
                        [disabled]="isDecoLocationSelected(position.id)"
                      >
                        {{ position.name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Service</mat-label>
                  <mat-select
                    formControlName="serviceId"
                    (selectionChange)="onDecoServiceChange(i, 'deco')"
                  >
                    <ng-container *ngFor="let service of getDecoServices(i)">
                      <ng-container *ngIf="service.id != 11">
                        <mat-option [value]="service.id">
                          {{ service.name }}
                        </mat-option>
                      </ng-container>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Color</mat-label>
                  <mat-select
                    formControlName="colorId"
                    multiple
                    (selectionChange)="updateColorCount(i)"
                  >
                    <mat-option
                      *ngFor="let color of getDecoInkColors(i)"
                      [value]="color.id"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        "
                      >
                        <span>{{ color.name }}</span>
                        <div
                          style="
                            height: 20px;
                            width: 20px;
                            border: 1px solid black;
                          "
                          [style.background-color]="color.hexValue"
                        ></div>
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Color Count</mat-label>
                  <input
                    type="number"
                    readonly
                    matInput
                    formControlName="colorCount"
                  />
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="getDecoPrice(true)"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div> -->
