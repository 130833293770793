<div class="grid-container">
    <div class="cart-detail">
      <div class="title"> YOUR CART </div>
      <div class="cart-list-container row">
        <div class="cart col-6" *ngFor="let product of cartItems?.cartItemsInfo; let i = index">
          <div class="prod-img" 
          [ngStyle]="{'background-image':'url('+(product?.productImages ? product?.productImages[0] : noImagePlaceholder)+')'}">
          </div>
          <div class="prod-detail">
            <p class="prod-name">
              {{product?.displayName ? product?.displayName : product?.productName}}
            </p>
            <p class="style-name" *ngIf="product?.styleName">
              {{product?.styleName || ""}}
            </p>
            <p class="order-type">
              {{product?.teamStoreId ? "Store Order (" + (product?.teamStoreName || " - ") + ")" : "Web Order"}}
            </p>
            <table class="prod-info">
              <tr>
                <th>Color :</th>
                <td>{{product?.cartItemGrids[0]?.color}}</td>
              </tr>
              <tr>
                <th>Sizes :</th>
                <td>
                  <ng-container *ngFor="let p of product?.cartItemGrids">
                    <div *ngIf="p.quantity && p.quantity>0" class="w-100 p-1">{{p.size}}</div>
                  </ng-container>
                </td>
                <td class="numbers">
                  <ng-container *ngFor="let p of product?.cartItemGrids">
                    <div *ngIf="p.quantity && p.quantity>0" class="w-100 p-1">({{p.quantity}})</div>
                  </ng-container>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  Total Quantity :
                </th>
                <td class="numbers">
                  {{product.quantity}}
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  Total Price :
                </th>
                <td class="numbers total-price">
                  ${{product.totalPrice}}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="sub-total">
      <div class="title"> SUB TOTAL </div>
        <p class="sub-total-amount">
          ({{cartItems? cartItems.cartItemsInfo.length : 0}}) Items: ${{totalAmount}}
        </p>
    </div> -->
  </div>
  