import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/core/services/store.service';
@Component({
  selector: 'app-layout-for-screen-capture',
  templateUrl: './layout-for-screen-capture.component.html',
  styleUrls: ['./layout-for-screen-capture.component.scss']
})
export class LayoutForScreenCaptureComponent implements OnInit {

  constructor(
    private storeService: StoreService,
    private route: ActivatedRoute
  ) { }

  noImagePlaceholder = "/assets/images/default-image.jpg"
  uniqueProducts = []

  ngOnInit() {
    this.getTeamStore()
  }

  getTeamStore() {
    let teamStoreId = this.route.snapshot.params.storeId;
    let selectedMapCodes = []
    this.storeService.getCategoriesWithProducts(teamStoreId).subscribe(res => {
      res.data.forEach(c => {
        c.products.forEach(p => {
          if (!selectedMapCodes.includes(p.mapCode)) {
            this.uniqueProducts.push(p)
            selectedMapCodes.push(p.mapCode)
          }
        })
      });
    })
  }
}