import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-count-down-timer',
  templateUrl: './count-down-timer.component.html',
  styleUrls: ['./count-down-timer.component.scss']
})
export class CountDownTimerComponent implements OnInit, OnDestroy {

  @Input() layoutData: any;
  @Input() teamStoreData: any;

  constructor() { }
  private subscription: Subscription;
  public dateNow = new Date();
  public dDay = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;


  ngOnInit () {
    this.subscription = interval(1000)
      .subscribe(x => {
        this.dDay = new Date(this.layoutData.closeDate);
        this.getTimeDifference();
      });
  }

  ngOnDestroy () {
    this.subscription.unsubscribe();
  }

  private getTimeDifference () {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits (timeDifference) {
    this.secondsToDday = Math.max(Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute),0)
    this.minutesToDday = Math.max(Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute),0)
    this.hoursToDday = Math.max(Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay),0)
    this.daysToDday = Math.max(Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay)),0)

    if(this.secondsToDday+this.minutesToDday+this.hoursToDday+this.daysToDday == 0){
      this.subscription.unsubscribe();
    }
  }


}
