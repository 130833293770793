import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StoreManagerService {
  private baseUrl = environment.apiUrl + "v1/";

  constructor(private http: HttpClient) { }

  getProductCategory() {
    return this.http
      .get<any>(this.baseUrl + "products/categories", { observe: "response" })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getProduct() {
    return this.http
      .get<any>(this.baseUrl + "products?orderby=date&order=desc", { observe: "response" })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDashboardDetailsCount() {
    return this.http
      .get<any>(this.baseUrl + "dashboard/user/0", { observe: "response" })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPendingOrderLIst(params?) {
    let url = this.baseUrl + "order/payments/pending?"
    if (params) {
      url += 'orderId=' + (params.orderId || 0)
      url += '&referenceId=' + (params.referenceId || '')
      url += '&customerName=' + params.customerName
      url += '&customerEmail=' + params.customerEmail
      url += '&organization=' + params.organization
      url += '&orderStatus=' + params.orderStatus
      url += '&type=' + params.purchaseType
      url += '&per_page=' + (params.per_page || 10)
      url += '&page=' + (params.page || 1)
    }
    return this.http
      .get<any>(url, { observe: "response" })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  approvePayment(orderId: any, status: any) {
    return this.http.put(this.baseUrl + "order/" + orderId + "/status/" + status, {}).pipe(
      map((response: any) => {
        const order = response;
        if (order) {
          return response;
        }
      })
    );
  }

  updatePendingOrderFields(formData) {
    return this.http.put(this.baseUrl + "Order/payment/pending/poupdate", formData).pipe(
      map((response: any) => {
        const order = response;
        if (order) {
          return response;
        }
      })
    );
  }


  getOrderList(params) {
    return this.http
      .get<any>(
        environment.apiUrl + "v1/order/list"
        + "?orderId=" + (params.orderId || 0)
        + "&customerName=" + (params.customerName || '')
        + "&institution=" + (params.institution || '')
        + "&customerEmail=" + (params.customerEmail || '')
        + "&customerPhone=" + (params.customerPhone || '')
        + "&startDate=" + (params.startDate || '')
        + "&endDate=" + (params.endDate || '')
        + "&orderType=" + (params.orderType || '0')
        + "&paymentMode=" + (params.paymentMode || '0')
        + "&exportStatus=" + (params.exportStatus || '')
        + "&herculesId=" + (params.herculesId || '')
        + "&per_page=" + (params.per_page || 0)
        + "&teamStoreId=" + (params.storeId || 0)
        + "&teamStoreName=" + (params.storeName || '')
        + "&orderBy=" + (params.orderBy || '')
        + "&order=" + (params.order || '')
        + "&page=" + (params.page || 0)
        + "&stateCode=" + (params.stateCode || '')

        , { observe: "response" })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getBulkOrderList(params) {
    return this.http
      .get<any>(
        environment.apiUrl + "v1/order/bulk/list"
        + "?orderId=" + (params.orderId || 0)
        + "&customerName=" + (params.customerName || '')
        + "&institution=" + (params.institution || '')
        + "&customerEmail=" + (params.customerEmail || '')
        + "&customerPhone=" + (params.customerPhone || '')
        + "&startDate=" + (params.startDate || '')
        + "&endDate=" + (params.endDate || '')
        + "&orderType=" + (params.orderType || '0')
        + "&paymentMode=" + (params.paymentMode || '0')
        + "&exportStatus=" + (params.exportStatus || '')
        + "&herculesId=" + (params.herculesId || '')
        + "&per_page=" + (params.per_page || 0)
        + "&teamStoreId=" + (params.storeId || 0)
        + "&teamStoreName=" + (params.storeName || '')
        + "&orderBy=" + (params.orderBy || '')
        + "&order=" + (params.order || '')
        + "&page=" + (params.page || 0)
        + "&stateCode=" + (params.stateCode || '')

        , { observe: "response" })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getOrderDetails(orderId: any) {
    return this.http
      .get<any>(environment.apiUrl + "v1/order/" + orderId + "/summary", { observe: "response" })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getOrder(orderId: any) {
    return this.http
      .get<any>(environment.apiUrl + "v1/order/" + orderId, { observe: "response" })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getOrderOutputStoresList(params) {
    let url = environment.apiUrl + 'v1/teamstore/orders/output?'
    url += "per_page=" + (params.per_page || '0')
      + "&Id=" + (params.id || 0)
      + "&Name=" + (params.name || '')
      + "&ArtsCount=" + (params.artsCount || '')
      + "&HerculesId=" + (params.herculesId || '')
      + "&ExportStatus=" + (params.exportStatus || '')
      + "&OpenDateFrom=" + (params.openDateFrom || '')
      + "&OpenDateTo=" + (params.openDateTo || '')
      + "&CloseDateFrom=" + (params.closeDateFrom || '')
      + "&CloseDateTo=" + (params.closeDateTo || '')
      + "&ContactName=" + (params.contactName || '')
      + "&page=" + (params.page || 1)
      + "&OrderBy=" + (params.orderBy || '')
      + "&Order=" + (params.order || '')
      + "&stateCode=" + (params.stateCode || '')

    if (params.stage)
      url += "&stage=" + params.stage
    return this.http.get(url, { observe: "response" })
  }

  getOrderOutput(teamstoreId, herculesId, live?) {
    let url = environment.apiUrl + `v1/teamstore/${teamstoreId}/export/v2/hercules/${herculesId}?liveStore=${live || 'false'}`
    // let url = environment.apiUrl + `v1/teamstore/${teamstoreId}/export/hercules/${herculesId}?liveStore=${live || 'false'}`
    return this.http.post(url, {})
  }

  updateHercIdWebForOrders(orderId, herculesId) {
    let url = environment.apiUrl + `v1/order/${orderId}/weborders/export/hercules/${herculesId}`
    return this.http.put(url, {})
  }

  getStoreOrderByStoreId(params) {
    return this.http.get(environment.apiUrl + "v1/teamstore/" + params.storeId + "/orders?per_page=" + params.per_page + "&page=" + params.page, { observe: "response" })
  }

  cancelProduct(id) {
    return this.http.put(environment.apiUrl + "v1/order/lineitem/" + id, {})
  }

  cancelProductPersonalization(data) {
    return this.http.post(environment.apiUrl + "v1/order/teamstore/personalization/refund/", data)
  }

  cancelOrder(orderId) {
    return this.http.post(environment.apiUrl + `v1/order/${orderId}/refund/v2`, {})
  }

  getAllManagerList() {
    return this.http.get(environment.apiUrl + "v1/teamstore/managers")
  }

  republishStore(storeId) {
    return this.http.post(environment.apiUrl + "v1/teamstore/" + storeId + "/republish", {})
  }

  _exportOrderDetails(orderId: number) {
    return this.http.post<any>(`${this.baseUrl}/teamstore/orderdetailpdf/${orderId}`, {}).pipe(
      map(response => {
        return response
      })
    );
  }
}