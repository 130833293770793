<!-- <form [hidden]="isCustomOrderFieldsHidden"> -->
<div class="row padding10">
  <div class="col-md-12 padding10">
    <!-- <p style="font-size: x-large;">Custom Order Fields</p><br> -->
    <mat-hint>Create custom checkout fields to collect any additional information from customers. Buyers will see the
      following custom fields when they process orders from checkout.</mat-hint>
  </div>
  <!-- <div class="col-md-4 padding10">
    <button class="btn btn-primary btnClass" (click)="submit()">SAVE & PUBLISH</button>
  </div> -->
</div>
<div class="row padding10">
  <div class="col-md-12 padding10">
    <mat-label>Active Custom Checkout Fields</mat-label><br>
  </div>
  <div class="col-md-12 padding10">
    <mat-hint>Drag and drop custom checkout fields to set the order that they appear to customers when they checkout.
    </mat-hint>
  </div>

  <div class="col-md-12" *ngIf="selectedCustomFields?.length>0">
    <div cdkDropList class="drag-list" (cdkDropListDropped)="drop($event)">
      <div class="drag-box" *ngFor="let field of selectedCustomFields;let i=index" cdkDrag>
        <div class="col-md-1" style="border: 1 solid black;">
          <mat-icon aria-hidden="false" aria-label="drag icon">open_with</mat-icon>
        </div>
        <div class="col-md-9">
          <mat-label class="drop-label">{{field.fieldLabel}}</mat-label><br><br>
          <mat-hint>Select Field Type: {{field.customFieldType==0?'Text':'Dropdown'}} &nbsp;&nbsp;
            <span style="color: black;">Required: {{field.isMandatory?'Yes':'No'}}</span>
          </mat-hint>
        </div>
        <div class="col-md-1" style="color:rgb(12, 131, 225);cursor: pointer;">
          <a (click)="openEditModal(i)">Edit</a>
        </div>
        <div class="col-md-1" style="cursor: pointer;">
          <mat-icon aria-hidden="false" aria-label="clear icon" (click)="setDeleteIndex(i)" data-toggle="modal"
            data-target="#deletealert">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 padding10">
    <button type="button" class="btn btn-primary btnClass" (click)="openCreateModal()">
      ADD CUSTOM FIELD
    </button>
  </div>
</div>
<!-- </form> -->


<form [formGroup]="customCheckoutFields">
  <div class="modal fade" id="customOrderFieldModal" role="dialog" style="max-height: 100vh;">
    <div class="modal-dialog" style="max-height: 95vh;">
      <div class="modal-content" style="max-height: 90vh;overflow-y: scroll;">
        <div class="modal-header">
          <mat-label *ngIf="!edit" style="font-size: large;">Create Custom Checkout Field</mat-label>
          <mat-label *ngIf="edit" style="font-size: large;">Update Custom Checkout Field</mat-label>
          <button type="button" class="close" (click)="closeModal()">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <mat-label>Select Field Type</mat-label>
            </div>
            <div class="col-md-12">
              <mat-form-field class="wrapField formField" style="width: 100%;">
                <!-- <mat-label>--Select Option--</mat-label> -->
                <mat-select style="height: 25px" formControlName="customFieldType"
                  (selectionChange)="onTypeChange($event)">
                  <mat-option [value]="0" selected>Text Entry Box</mat-option>
                  <mat-option [value]="1">Multiple Choice Dropdown</mat-option>
                </mat-select>
                <br>
              </mat-form-field>
              <mat-checkbox class="padding10" formControlName="isMandatory">Make this field required to complete order
              </mat-checkbox>
            </div>
            <div class="col-md-12">
              <mat-divider class="padding10"></mat-divider>
            </div><br>
            <div class="col-md-12">
              <mat-label>Field Label</mat-label>
            </div><br>
            <div class="col-md-12">
              <mat-form-field class="wrapField formField" style="width: 100%;">
                <input type="text" matInput formControlName="fieldLabel" required>
                <mat-error *ngIf="customCheckoutFields.get('fieldLabel').errors?.required">
                  Field Label is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-label>Field Instructions</mat-label> &nbsp;
              <mat-hint class="smaller">Optional</mat-hint>
            </div><br>
            <div class="col-md-12">
              <mat-form-field class="formField" style="width: 100%;">
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2"
                  formControlName="fieldInstruction"></textarea>
              </mat-form-field>
            </div>
            <div *ngIf="isMulitpleChoiceDropdownSelected">
              <div class="col-md-12">
                <mat-label style="font-weight: 500;">Field Choices</mat-label><br><br>
                <mat-label>Enter each choice on its own line. Each line will be a choice a customer can select within
                  the dropdown.</mat-label>
              </div><br>
              <div class="col-md-12">
                <mat-form-field class="formField" style="width: 100%;">
                  <textarea matInput cdkTextareaAutosize formControlName="multipleChoices" required (input)="updateMultipleChoiceArray()"
                    cdkAutosizeMinRows="4"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-12 field-preview">

              <div class="field-preview-header">
                <p class="preview-label">Preview this Custom Field</p>
              </div>
              <div class="preview-element">
                <label for="checkoutTextField" class="field-label">{{customCheckoutFields.controls.fieldLabel.value || 'Field Value'}}</label>
                <span *ngIf="customCheckoutFields.controls.isMandatory.value" style="color: red;">*</span>
                <br>
                <p class="field-instruction">{{customCheckoutFields.controls.fieldInstruction.value || 'Field Instruction'}}</p>
                <div class="" *ngIf="!isMulitpleChoiceDropdownSelected" style="width: 100%;">
                  <div style="width: 100%;">
                    <mat-form-field class="wrapField formField" style="width: 100%;">
                      <input type="text" matInput style="width: 100%;">
                    </mat-form-field>
                  </div>
                </div>
                <div class="" *ngIf="isMulitpleChoiceDropdownSelected" style="width: 100%;">
                  <mat-form-field class="wrapField formField" style="width: 100%;" floatLabel="never">
                    <mat-label>--Select Option--</mat-label>
                    <mat-select>
                      <mat-option [value]="option" *ngFor="let option of multipleChoiceArray">{{option}}</mat-option>
                    </mat-select>
                    <br>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <br>
            <div class="col-md-12">
              <button class="btn btn-primary btnClass" style="float: right;" (click)="addField()"
                *ngIf="!edit">CREATE</button>
              <button class="btn btn-primary btnClass" style="float: right;" (click)="editField()"
                *ngIf="edit">UPDATE</button>
              <button class="btn" style="float: right;" (click)="closeModal()">CANCEL</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div  *ngIf="selectedCustomFields?.length>0" class="modal" tabindex="-1" role="dialog" id="deletealert">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Field</h5>
        <button type="button" class="close" (click)="closeDeleteModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to delete the custom field {{selectedCustomFields[deleteIndex]?.fieldLabel}} ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeleteModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteField();closeDeleteModal()"  >Delete Field</button>
      </div>
    </div>
  </div>
</div>