import {  AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { ProductService } from 'src/app/core/services/product.service';
import { environment } from 'src/environments/environment';
const DEFAULT_DURATION = 300;
import { Location } from '@angular/common';
@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class DesignerComponent implements OnInit {
  backButton=false
  showMore=false
  product: any;
  categoryList = []
  searchText: any;
  searchTemplateText:string="";
  paginatorIndex: number = 0
  paginatorIndexTemplate: number = 0

  paginatorPageSize: number = 10
  paginatorPageSizeTemplate: number = 10

  selectedSortOption: any = {}
  selectedSortOptionTemplate: any = {}

  productList: any[] = [];
  paginatorLength: number
  paginatorPageSizeOptions: number[] = [5, 10, 25, 100]
  categoryId: any;
  loading = false
  selectedColor
  templateCategoryList: any;
  selectedTemplate: any;
  paginatorLengthTemplate: any;
  templateList: any[] = [];
  productView: boolean=false;
  opendesignGallery: boolean=false;
  isEndUser:boolean=false
  comparableProducts: string;
  click: string;
  isHidden: boolean = false;
  isHiddenDesignGalley: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private productService: ProductService,
    private router: Router,private location: Location
  ) {
    const currentUrl = this.location.path();
    this.isEndUser = currentUrl.includes('enduser'); 
   }

  showFloatingData = false
  showFloatingIcon = false
  noImage = "../../../../assets/images/no-image.jpg"
  storeId: number
  productId
  user: any;
  isImage: any = 1;
  isUser: any = 0;
  designData
  private srcString = `${environment.designerUrl}`;
  productIndex
  templateRefId
  collapsed = true;
  firstcollapsed=true;
  isDesignGalleryCollapsed=true;

  toggle() {
    this.collapsed = !this.collapsed;
  }

  expand() {
    this.collapsed = false;
  }

  collapse() {
    this.collapsed = true;
  }
  firstcollapse(){
    this.firstcollapsed = true;
  }
  firstexpand(){
    this.firstcollapsed = false;
  }
  openNav() {
    this.firstcollapsed=false  
    setTimeout(() => { 
    document.getElementById("mySidenav").style.width = "308px";
    setTimeout(() => { 
    this.comparableProducts="Comparable Products"
    this.click="Click to swap the currently displayed product with one of our custom suggested  products for you"
  }, 
    350);
   
    }, 300);
  }
  openDesignGallery(){
    this.isHiddenDesignGalley=true
    this.opendesignGallery=true  
    setTimeout(() => {
      document.getElementById("mySidenav3").style.width = "308px";
    this.isHiddenDesignGalley= !this.isHiddenDesignGalley

    }, 300);
  }
  closeDesignGallery(){
    this.isHiddenDesignGalley=true
    document.getElementById("mySidenav3").style.width = "0";
   setTimeout(() => {
    this.opendesignGallery=false  
    }, 300);
  }
  openProducts() { 
    this.isHidden=true
    this.productView=true;
    setTimeout(() => {
    document.getElementById("mySidenav2").style.width = "75%";
    this.isHidden = !this.isHidden;
    }, 300);

  }
  closeProducts(){
    this.isHidden=true
    document.getElementById("mySidenav2").style.width = "0";
   setTimeout(() => {
    this.productView=false  
    }, 300);
  }
  
  closeNav() {
    this.comparableProducts=''
    this.click=''  
    document.getElementById("mySidenav").style.width = "0";
    setTimeout(() => {
      this.firstcollapsed=true 
    }, 300);
  }
  ngOnInit() {
     
    this.route.paramMap.subscribe((params: ParamMap) => { 
      this.loading = true
      this.initialLoad()
      this.loading = false
    });
    this.route.queryParamMap.subscribe((params: ParamMap) => { 
      this.loading = true
      this.initialLoad()
      this.loading = false
    });
    if(window.innerWidth >= 768){
      this.openNav()
    this.openDesignGallery();
    }
  }
  initialLoad(){  
      window.parent.postMessage("update title-Design Studio", "*") 
      this.user = JSON.parse(localStorage.getItem("user"));
      if(!this.user || !this.user.roles.some(x=>x.name=='DESIGNER'))  //restrict i button to use except builder
      {
        localStorage.removeItem('designData')
      }
      this.designData = JSON.parse(localStorage.getItem('designData'))
      if (this.designData)
        this.showFloatingIcon = true
      this.storeId = parseInt(localStorage.getItem("teamStoreId")) | 0;
      this.productId = this.route.snapshot.paramMap.get('productId')
      if(!this.productId)
        this.productId = environment.baseProduct
      this.productIndex = localStorage.getItem('builderDesignedProductIndex')
      this.productIndex = this.productIndex ? Number(this.productIndex) + 1 : 0
      // this.productId = parseInt(this.route.snapshot.paramMap.get('productId'));
      if (this.storeId == 0) {
        this.isUser = 1;
        this.isImage = 0;
      }
      this.templateRefId = this.route.snapshot.queryParams['templateRefId']
      this.setSrc()
      this.getProductDetail();
      this.getProductCategory()
      this.getTemplateCategory()
      window.scroll(0, 0);
  }
  onSearchKeypress(event) {
    if (event.charCode == 13)
      this.getProduct()
  }
  onTemplateSearchKeypress(event) {
    if (event.charCode == 13)
      this.getTemplates()
  }
  getProductDetail() {

    this.productService.getProductDetail(this.productId).subscribe(response => {
      this.product = response.products;
      this.selectedColor = this.product.variants[0]
       this.loading = false
    },
      error => {
        this.commonService.openErrorSnackBar(error.message, "");
      });
  }
  getProductCategory() {
    this.productService.getProductCategory().subscribe(
      (response) => {
        var result = response.body.data;
        this.categoryList = result.map(function (el) {
          var o = Object.assign({}, el);
          o.isExpanded = false;
          if (o.parent_id == 0)
            return o;
        })
        this.getProduct()
      },
      (error) => {

      }
    );
  }
  getTemplateCategory() {
    this.productService.getTemplateCategory().subscribe(
      (response) => {
        this.templateCategoryList = response.body.data; 
        this.templateCategoryList = this.templateCategoryList.filter(item => item.is_disable !== 1);

        this.getTemplates()
      },
      (error) => {

      }
    );
  }
  selectTemplateCategory(item){
    this.selectedTemplate=item;
    this.getTemplates()
  }
  getTemplates(){
    this.loading = true
    let category=[]
    category.push(this.selectedTemplate?.id)
    let params = {
      categoryId: category,
      name:this.searchTemplateText,
      sortby:'xe_id', 
      order: this.selectedSortOptionTemplate.order||'desc',

      page: (this.paginatorIndexTemplate + 1),
      perpage: this.paginatorPageSizeTemplate,
      printprofile:'', 
      colorused:0,
      tag:'' 
    }
    this.productService.getTemplatesByCategory(params).subscribe(
      (response) => {
        this.templateList = response.body.data;
        this.templateList = this.templateList.filter(item => item.is_disable !== 1);

        this.paginatorLengthTemplate =  response.body.total_records

        this.loading = false
        this.commonService.postScrollMessageToParent()
      },
      (error) => {
        this.templateList = [];
        this.paginatorLengthTemplate = 0
        this.loading = false
      }
    );
  }
  selectCategory(category) {
    // let url = '/enduser/directpurchase/productlist'
    // if (category.id)
    //   url += ('?category=' + category.id)
    // this.router.navigateByUrl(url);
    this.categoryId=category.id
    this.getProduct()
  }
  getProduct( ) {
    this.loading = true
    let params = {
      categoryId: this.categoryId,
      page: (this.paginatorIndex + 1),
      per_page: this.paginatorPageSize,
      // viewType:2, 
      storeStatuses: '2,3',
      keyword: this.searchText,
      orderBy: this.selectedSortOption.value,
      order: this.selectedSortOption.order,
    }
    this.productService.getProduct(params).subscribe(
      (response) => {
        this.productList = response.body.data;
        this.paginatorLength = JSON.parse(response.headers.get("Pagination")).totalItems
        this.loading = false
        this.commonService.postScrollMessageToParent()
      },
      (error) => {
        this.productList = [];
        this.paginatorLength = 0
        this.loading = false
      }
    );
  }
  pageEvent(event) {
    this.paginatorPageSize = event.pageSize
    this.paginatorIndex = event.pageIndex
    this.getProduct();
  }
  pageEventTemplate(event) {
    this.paginatorPageSizeTemplate = event.pageSize
    this.paginatorIndexTemplate = event.pageIndex
    this.getTemplates();
  }
  setSrc() {
    let userId = this.user ? this.user.id : 0
    if (this.router.url.includes('storemanager/bulkorderentry/designer/')) { 
       userId= localStorage.getItem("bulkOrderEntryUserId");
       let bulkOrderEntryId= localStorage.getItem("bulkOrderEntryId");
       this.productIndex=999
      this.storeId=Number(bulkOrderEntryId) 

    } 

    let iframe = <HTMLIFrameElement>document.getElementById('designeriframe')
    let url = this.srcString + "index.html?id=" + this.productId
    + "&vid=45&qty=" + (this.productIndex || 0)
    + "&pbti=0&store_id=1&teamstoreId=" + this.storeId
    + "&userId=" + userId + "&customer=" + userId
    + "&isImage=" + this.isImage + "&isUser=" + this.isUser +"&added_price=1"
    if(this.templateRefId)
      url += "&etid=" + this.templateRefId
    iframe.src = url
    this.commonService.postHeightToParent("medium")
  }

  // stage
  // iframe.src = this.srcString + "designer/index.html?id=" + this.productId + "&vid=45&qty=1&pbti=0&store_id=1&teamstoreId=" + this.storeId + "&userId=" + this.user.id + "&isImage=" + this.isImage + "&isUser=" + this.isUser;

  // dev
  // iframe.src = this.srcString + "designer/index.html?id=" + this.productId + "&vid=45&qty=1&pbti=0&store_id=" + this.storeId + "." + this.user.id + "&customer_id=2";
  toggleFloatingData() {
    this.showFloatingData = !this.showFloatingData
  }

  goToDesigner(item) { 
    localStorage.setItem('productId', item.id);
    // if (!this.isUserLoggedIn("design product")) return
    localStorage.setItem("purchaseType", '2');
    let url = '/enduser/directpurchase/designer/' + item.id 
    if (this.selectedColor)
      url = url + "-" + this.selectedColor.id

    if (this.templateRefId)
      url += "?templateRefId=" + this.templateRefId

    this.router.navigateByUrl(url); 
     
  }
  templateClicked(item){
    //  if (!this.isUserLoggedIn("design product")) return
     //localStorage.setItem("purchaseType", '2');
     let url = '/enduser/directpurchase/designer/' + this.productId+"?templateRefId=" + item.ref_id
    this.router.navigateByUrl(url); 
  }
  isUserLoggedIn(action) {
    let userId = localStorage.getItem("userId");
    if (!userId) {
      window.parent.postMessage("open guest login", "*");
      window.parent.postMessage("action - " + action, "*");
      return false
    } else {
      return true
    }
  }
}
