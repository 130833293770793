<form [formGroup]="storeClosedPageGroup">
  <div class="row">
      <div class="col-md-8">
        <p style="font-size: x-large;">Store Closed Page</p>
        <mat-hint>Customize the messaging shown on your store closed page.</mat-hint>
      </div>
      <div class="col-md-4" style="text-align: right;">
        <button class="btn btn-primary btnClass" (click)="saveTeamStoreChanges()">SAVE</button>
      </div>
    </div><br>
    <div class="row padding10">
    <div class="col-md-12">
      <mat-label>Title</mat-label>&nbsp;
      <!-- <mat-hint class="smaller">Optional</mat-hint> -->
      <mat-form-field class="formField"  style="width: 100%;">
        <input type="text" matInput formControlName="title" readonly/>
        <mat-error *ngIf="storeClosedPageGroup.get('title').errors?.maxlength">
          Max character limit reached
          </mat-error>
        <!-- <mat-hint>Maximum allowed characters: 50</mat-hint> -->
      </mat-form-field>
    </div>
    <div class="padding10"></div>
    <div class="col-md-12">
      <mat-label>Description</mat-label>&nbsp;
      <!-- <mat-hint class="smaller">Optional</mat-hint> -->
      <mat-form-field class="formField"  style="width: 100%;">
        <input type="text" matInput formControlName="description" readonly/>
        <!-- <mat-hint>Note: The first name and last name will not appear on the store front</mat-hint> -->
      </mat-form-field>
    </div>
    <div class="col-md-12"><br>
        <!-- <mat-checkbox class="example-margin" formControlName="displayGraphicImage" [checked]="true" >Display store closed/fundraiser graphic image</mat-checkbox> -->
    </div>
    <div class="col-md-12">
      <!-- <mat-checkbox class="example-margin" formControlName="displayContactInfo" [checked]="true" >Display store contact info</mat-checkbox> -->
    </div>
    <div class="padding10"></div>
    <div class="col-md-12">
      <button class="btn btn-primary btnClass">PREVIEW PAGE</button> &nbsp;
    </div>
    </div>
</form>