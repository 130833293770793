import { Component } from "@angular/core";
import { CommonService } from "./core/services/common.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Jupiter";
  loading = false
  constructor(
    private commonService: CommonService,
  ) {
    this.commonService.loading$.subscribe((res) => {
      this.loading = res;
    });
  }
}
